<template>
  <div class="section-list">
    <div
      class="text-wrapper"
      v-for="(key, value) in content.blocks"
      :key="value"
    >
      <b v-html="key.title"></b>
      <p v-html="key.description"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-list",
  props: {
    content: Object
  }
};
</script>

<style lang="scss" scoped>
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;

.section-list {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  .text-wrapper {
    margin-bottom: 15px;

    max-width: 830px;
    width: 100%;
    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    p {
      font-size: 16px;
      color: #545454;
      font-family: $fontR;
      margin-bottom: 15px;

      @media screen and (max-width: 670px) {
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      b,
      strong {
        display: block;
        font-weight: normal;
        color: #00a03b;
        font-family: $fontM;
        text-decoration: underline;
      }
    }

    b,
    strong {
      display: block;
      font-weight: normal;
      color: #00a03b;
      font-family: $fontM;
      text-decoration: underline;
    }
  }
}
</style>
