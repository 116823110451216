<template>
  <div class="section-full-chart">
    <h2 class="h3-title-chart" v-html="content.title"></h2>

    <div class="img-wrapper">
      <img :src="`${$root.globalUrl}${content.file}`" alt="" />
    </div>

    <div class="text-wrapper" v-html="content.description"></div>
  </div>
</template>

<script>
export default {
  name: "section-full-chart",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "text_image_description"
  })
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-full-chart {
  margin: 15px 0;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  .h3-title-chart {
    ::v-deep p {
      color: #00a03b;
      font-family: $fontDemi;
      font-size: 30px;
      margin-bottom: 15px;
      @media screen and (max-width: 670px) {
        font-size: 18px;
      }
    }

    color: #00a03b;
    font-family: $fontDemi;
    font-size: 30px;
    margin-bottom: 15px;
    @media screen and (max-width: 670px) {
      font-size: 18px;
    }
  }

  .img-wrapper {
    width: 100%;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  ::v-deep p {
    margin-bottom: 15px;
    color: #545454;
    font-size: 16px;
    font-family: $fontR;

    @media screen and (max-width: 670px) {
      font-size: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
