import { render, staticRenderFns } from "./section-image_right_text_with_background_left.vue?vue&type=template&id=6048bca6&"
import script from "./section-image_right_text_with_background_left.vue?vue&type=script&lang=js&"
export * from "./section-image_right_text_with_background_left.vue?vue&type=script&lang=js&"
import style0 from "./section-image_right_text_with_background_left.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports