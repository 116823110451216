<template>
  <div class="section-links" v-html="content.legalText"></div>
</template>

<script>
export default {
  name: "section-links",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-links {
  margin: 15px 0;
  padding: 54px;
  background: #f5f5f5;

  * {
    font-family: $fontR;
    word-break: break-word;
    font-size: 12px;
  }

  ul {
    padding-left: 15px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 650px) {
    margin: 15px 0;
  }

  @media screen and (max-width: 425px) {
    padding: 20px 15px;
    margin: 15px -15px;
  }

  p {
    color: #484a4b;
    font-family: $fontR;
    font-size: 12px;
    margin-bottom: 20px;

    b,
    strong {
      font-family: $fontB;
      font-weight: normal;
    }

    a {
      color: #484a4b;
      text-decoration: underline;
    }
  }
}
</style>
