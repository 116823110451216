<template>
  <div class="section-double-col-text">
    <h3 v-html="content.title"></h3>

    <div class="text-wrapper" v-html="content.description"></div>
  </div>
</template>

<script>
export default {
  name: "section-double-col-text",

  props: {
    content: Object
  },

  data: () => ({
    nameSection: "text_with_title_vertical"
  })
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-double-col-text {
  margin: 15px 0;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  .h3-title,
  h3,
  h3 p {
    color: #00a03b;
    font-family: $fontR;
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 40px;

    @media screen and (max-width: 670px) {
      font-size: 18px;
      margin-bottom: 15px;
    }

    b,
    strong {
      font-weight: normal;
      font-family: $fontDemi;
    }
  }

  p {
    margin-bottom: 15px;
    color: #545454;
    font-family: $fontR;
    font-size: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    @media screen and (max-width: 670px) {
      font-size: 14px;
    }
  }
}
</style>
