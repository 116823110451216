<template>
  <div class="section-row-text-img">
    <div class="text-wrapper">
      <h3 v-html="content.title"></h3>
      <div class="text-wrapper" v-html="content.description"></div>
    </div>
    <div class="img-wrapper">
      <img :src="`${$root.globalUrl}${content.file}`" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-text-img",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-row-text-img {
  margin: 15px 0;
  display: flex;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }
  @media screen and (max-width: 500px) {
    flex-direction: column;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  > div {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 425px) {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &.img-wrapper {
      @media screen and (max-width: 425px) {
        margin: 0 -15px !important;
        width: auto;
      }
    }

    &:nth-child(1) {
      margin-right: 15px;
      @media screen and (max-width: 670px) {
        margin-right: 5px;
      }
      @media screen and (max-width: 500px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 500px) {
        margin-left: 0;
      }
    }

    h3 {
      color: #00a03b;
      font-family: $fontM;
      font-weight: normal;
      font-size: 20px;
      margin-bottom: 15px;
      @media screen and (max-width: 670px) {
        font-size: 16px;
      }
    }

    p {
      color: #545454;
      font-family: $fontR;
      font-size: 16px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 670px) {
        font-size: 14px;
      }
    }
  }
}
</style>
