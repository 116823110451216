<template>
  <div class="section-text-carousel">
    <div class="text">
      <h3 v-html="content.title"></h3>
      <div class="text-wrapper" v-html="content.description"></div>
    </div>

    <div class="carousel">
      <swiper :options="swiperOption3">
        <swiper-slide v-bind:key="value" v-for="(key, value) in content.blocks">
          <img :src="`${$root.globalUrl}${key.file}`" alt="" />
          <div class="text-wrapper">
            <p v-html="key.title"></p>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev3" slot="button-prev"></div>

        <div class="swiper-pagination" slot="pagination"></div>

        <div class="swiper-button-next3" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-text-carousel",
  props: {
    content: Object
  },
  data: () => ({
    swiperOption3: {
      autoHeight: true,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },

      navigation: {
        nextEl: ".swiper-button-next3",
        prevEl: ".swiper-button-prev3"
      }
    }
  })
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-text-carousel {
  display: flex;
  margin: 15px 0;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }
  @media screen and (max-width: 550px) {
    flex-direction: column;
  }

  > div {
    max-width: 400px;
    min-width: 260px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 550px;
    }
    @media screen and (max-width: 550px) {
      margin-bottom: 15px;
    }

    &.text {
      h3 {
        margin-bottom: 15px;
        color: #00a03b;
        font-family: $fontM;
        font-size: 20px;
      }

      p {
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &:nth-child(1) {
      margin-right: 15px;

      @media screen and (max-width: 666px) {
        margin-right: 5px;
      }
      @media screen and (max-width: 550px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 666px) {
        margin-left: 5px;
      }
      @media screen and (max-width: 550px) {
        margin-left: 0;
      }
      @media screen and (max-width: 550px) {
        margin-bottom: 0;
      }
    }

    &.carousel {
      .text-wrapper {
        position: absolute;
        left: 0;
        bottom: -120px;
        max-width: 400px;
        width: 100%;

        @media screen and (max-width: 550px) {
          bottom: -80px;
        }
        @media screen and (max-width: 425px) {
          padding: 0 15px;
        }

        p {
          color: #00a03b;
          font-family: $fontM;
          font-size: 20px;
          @media screen and (max-width: 550px) {
            font-size: 18px;
          }
        }
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }

      .swiper-container {
        padding-bottom: 120px;

        @media screen and (max-width: 550px) {
          padding-bottom: 80px;
        }
        @media screen and (max-width: 425px) {
          margin: 0 -15px;
        }
      }

      .swiper-pagination {
        position: relative !important;
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        bottom: 0 !important;
        height: 4px;
        @media screen and (max-width: 425px) {
          padding: 0 6px;
        }
      }

      .swiper-pagination-bullet {
        min-width: 5px;
        max-width: 100%;
        width: 100% !important;
        border-radius: 10px;
        height: 2px !important;
        transition: all 0.2s ease;

        &.swiper-pagination-bullet-active {
          background: #00a03b !important;
          min-width: 78px;
          height: 4px !important;
          @media screen and (max-width: 425px) {
            min-width: 40px;
          }
        }
      }

      .swiper-button-next3 {
        cursor: pointer;
        position: absolute;
        z-index: 5;
        background: url("../assets/img/article/arrow.png") no-repeat center !important;
        background-size: contain !important;
        width: 70px !important;
        height: 30px !important;
        top: auto !important;
        bottom: 160px;
        right: 30px !important;

        &.swiper-button-disabled {
          display: none;
        }

        @media screen and (max-width: 550px) {
          bottom: 120px;
        }
      }

      .swiper-button-prev3 {
        cursor: pointer;
        position: absolute;
        z-index: 5;
        background: url("../assets/img/article/arrow-left.png") no-repeat center !important;
        background-size: contain !important;
        width: 70px !important;
        height: 30px !important;
        top: auto !important;
        bottom: 160px;
        left: 30px !important;

        &.swiper-button-disabled {
          display: none;
        }

        @media screen and (max-width: 550px) {
          bottom: 120px;
        }
      }
    }
  }
}
</style>
