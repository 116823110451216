<template>
  <div class="section-picker">
    <div class="img-wrapper">
      <img :src="`${$root.globalUrl}${content.file}`" alt="" />

      <div
        @click="switchPicker(value + 1)"
        v-bind:class="[{ active: currentItem === value + 1 }]"
        :style="{ left: key.left + '%', top: key.top + '%' }"
        class="item-img-pick"
        v-for="(key, value) in content.block"
        :key="value + '1'"
      >
        <p>{{ value + 1 }}</p>
      </div>

      <div class="description-right-bottom desktop">
        <!--        <div class="prev"-->
        <!--             v-bind:class="[{ active: currentItem > 1 }]"-->
        <!--             @click="switchPicker(currentItem-1)">-->
        <!--          <div class="circle">-->
        <!--            <p>{{currentItem-1}}</p>-->
        <!--          </div>-->
        <!--        </div>-->

        <div class="now">
          <div class="stage">
            <p>{{ currentItem }}</p>
          </div>
          <p class="title" v-html="content.block[currentItem - 1].title"></p>
        </div>

        <!--        <div v-bind:class="[{ active: currentItem < content.block.length }]"-->
        <!--             @click="switchPicker(currentItem+1)"-->
        <!--             class="next-mobile">-->
        <!--          <div class="circle">-->
        <!--            <p>{{currentItem+1}}</p>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="description-right-bottom mobile">
      <!--      <div class="prev"-->
      <!--           v-bind:class="[{ active: currentItem > 1 }]"-->
      <!--           @click="switchPicker(currentItem-1)">-->
      <!--        <div class="circle">-->
      <!--          <p>{{currentItem-1}}</p>-->
      <!--        </div>-->
      <!--      </div>-->

      <div class="now">
        <div class="stage">
          <p>{{ currentItem }}</p>
        </div>
        <p class="title" v-html="content.block[currentItem - 1].title"></p>
      </div>

      <!--      <div v-bind:class="[{ active: currentItem < content.block.length }]"-->
      <!--           @click="switchPicker(currentItem+1)"-->
      <!--           class="next-mobile">-->
      <!--        <div class="circle">-->
      <!--          <p>{{currentItem+1}}</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="stage-wrapper">
      <div
        class="circle"
        @click="switchPicker(value + 1)"
        v-bind:class="[{ active: currentItem === value + 1 }]"
        v-for="(key, value) in content.block"
        :key="value + '2'"
      >
        <p>{{ value + 1 }}</p>
      </div>
    </div>

    <div class="description-text-wrapper">
      <h3 v-html="content.block[currentItem - 1].title"></h3>
      <div
        class="text-wrapper"
        v-html="content.block[currentItem - 1].description"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-picker",
  props: {
    content: Object
  },

  data: () => ({
    currentItem: 1,

    nameSection: "photo_picker"
  }),

  methods: {
    switchPicker(id) {
      this.currentItem = id;
    }
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-picker {
  margin: 15px 0;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  * {
    user-select: none;
  }

  .img-wrapper {
    position: relative;
    margin-bottom: 30px;

    @media screen and (max-width: 670px) {
      margin-bottom: 0;
    }
    @media screen and (max-width: 425px) {
      margin: 0 -15px 0 -15px;
    }

    .item-img-pick {
      cursor: pointer;
      position: absolute;

      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #005a85;

      display: flex;
      justify-content: center;
      align-items: center;

      transition: all 0.2s ease;
      user-select: none;

      p {
        font-family: $fontM;
        font-size: 13px;
        color: white;
      }

      &.active,
      &:hover {
        background: #00a03b;
        box-shadow: 0 0 0 10px #00a03b;
      }
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .description-right-bottom {
    background: #00a03b;
    max-width: 315px;
    width: 100%;
    min-height: 74px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    position: absolute;
    right: 0;
    bottom: 0;

    &.mobile {
      display: none;
      @media screen and (max-width: 670px) {
        display: flex;
        margin-bottom: 15px;
      }
      @media screen and (max-width: 425px) {
        width: auto;
        margin: 0 -15px 15px -15px;
      }
    }

    &.desktop {
      @media screen and (max-width: 670px) {
        display: none;
      }
    }

    @media screen and (max-width: 670px) {
      position: relative;
      max-width: 670px;
      justify-content: space-between;
    }

    .prev {
      cursor: pointer;
      width: 42px;
      height: 20px;
      background: white;
      border-radius: 0 50px 50px 0;
      padding: 2px;
      display: flex;
      visibility: hidden;
      justify-content: flex-end;
      margin-right: 24px;

      @media screen and (max-width: 670px) {
        margin-right: 0;
      }

      &.active {
        visibility: visible;
      }

      .circle {
        width: 16px;
        height: 16px;
        background: #005a85;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: white;
          font-size: 10px;
          font-family: $fontM;
        }
      }
    }

    .next-mobile {
      width: 42px;
      height: 20px;
      background: white;
      border-radius: 50px 0 0 50px;
      padding: 2px;
      display: none;
      justify-content: flex-start;
      visibility: hidden;

      &.active {
        visibility: visible;
      }

      @media screen and (max-width: 670px) {
        display: flex;
      }

      .circle {
        width: 16px;
        height: 16px;
        background: #005a85;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;

        p {
          color: white;
          font-size: 10px;
          font-family: $fontM;
        }
      }
    }

    .now {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 15px;

      @media screen and (max-width: 670px) {
        margin: 0 15px;
      }

      .title {
        max-width: 180px;
        color: white;
        font-size: 10px;
        font-family: $fontR;
      }

      .stage {
        min-width: 30px;
        min-height: 30px;
        max-width: 30px;
        max-height: 30px;
        background: white;
        margin-right: 8px;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        p {
          color: #00a03b;
          font-size: 15px;
          font-family: $fontM;
        }
      }
    }
  }

  .stage-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    @media screen and (max-width: 670px) {
      margin-bottom: 15px;
    }

    .circle {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      background: #005a85;
      width: 20px;
      height: 20px;

      border-radius: 50%;
      margin-right: 38px;

      position: relative;
      transition: all 0.2s ease;

      &:hover,
      &.active {
        background: #00a03b;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      &:after {
        content: "";
        background: #d3d3d3;
        width: 34px;
        height: 1px;
        position: absolute;
        right: -36px;
        top: 0;
        bottom: 0;
        margin: auto 0;
      }

      p {
        color: white;
        font-size: 10px;
        font-family: $fontM;
      }
    }
  }

  .description-text-wrapper {
    h3 {
      margin-bottom: 15px;
      color: #00a03b;
      font-family: $fontM;
      font-weight: normal;
      font-size: 20px;
      @media screen and (max-width: 670px) {
        font-size: 16px;
      }
    }

    p {
      margin-bottom: 15px;
      color: #545454;
      font-family: $fontR;
      font-size: 16px;
      @media screen and (max-width: 670px) {
        font-size: 14px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
