import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import page from '../views/page.vue'
import article from '../views/article-page.vue'
import remains from '../views/remains.vue'

Vue.use(VueRouter);


const routes = [
    // {
    //     path: '/',
    //     name: 'home',
    //     component: Home
    // },
    
    {
        path: '/',
        name: 'page',
        component: page,
        props: true,
    },
    {
        path: '/distributor/remains',
        name: 'remains',
        component: remains
    },
    {
        path: '/article/distributor/:id',
        name: 'article-page',
        component: article,
        props: true,
    }
];

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

export default router
