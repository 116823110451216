<template>
  <div class="section-slider-wrapper">
    <swiper :options="swiperOption" style="height: auto">
      <swiper-slide v-for="(key, value) in content.files" v-bind:key="value"
        ><img :src="`${$root.globalUrl}${key.path}`" alt=""
      /></swiper-slide>

      <div class="swiper-button-prev" slot="button-prev"></div>

      <div class="swiper-pagination" slot="pagination"></div>

      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
export default {
  name: "section-slider-wrapper",
  props: {
    content: Object
  },
  data: () => ({
    swiperOption: {
      autoHeight: true,

      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    }
  })
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-slider-wrapper {
  margin: 15px 0;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  .swiper-container {
    padding-bottom: 25px;
    @media screen and (max-width: 425px) {
      margin: 0 -15px;
    }
  }

  .swiper-pagination {
    position: relative !important;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0 !important;
    height: 4px;
    @media screen and (max-width: 425px) {
      padding: 0 6px;
    }
  }

  .swiper-pagination-bullet {
    min-width: 5px;
    max-width: 100%;
    width: 100% !important;
    border-radius: 10px;
    height: 2px !important;
    transition: all 0.2s ease;

    &.swiper-pagination-bullet-active {
      background: #00a03b !important;
      min-width: 78px;
      height: 4px !important;
      @media screen and (max-width: 425px) {
        min-width: 40px;
      }
    }
  }

  .swiper-button-next {
    background: url("../assets/img/article/arrow.png") no-repeat center !important;
    background-size: contain !important;
    width: 70px !important;
    height: 30px !important;
    top: auto !important;
    bottom: 70px;
    right: 30px !important;

    &.swiper-button-disabled {
      display: none !important;
    }
  }

  .swiper-button-prev {
    background: url("../assets/img/article/arrow-left.png") no-repeat center !important;
    background-size: contain !important;
    width: 70px !important;
    height: 30px !important;
    top: auto !important;
    bottom: 70px;
    left: 30px !important;

    &.swiper-button-disabled {
      display: none !important;
    }
  }
}
</style>
