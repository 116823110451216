<template>
  <div class="section-single-video">
    <video controls>
      <source :src="`${$root.globalUrl}${content.file}`" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "section-section-single-video-360",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1128px;
.section-single-video {
  margin: 15px 0;
  width: 100%;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 15px -15px;
    width: auto;
  }

  video {
    width: 100%;
    display: block;
    min-height: 464px;
    @media screen and (max-width: 425px) {
      min-height: 180px;
    }
  }
}
</style>
