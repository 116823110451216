<template>
  <div class="section-calculate-drug-type2">
    <div class="double-block-text">
      <div>
        <p>
          <span class="text" v-html="content.marketing_text"></span>

          <span class="future" v-if="state==='future'"
          >Майбутня акція</span
          >
          <span
              class="active"
              v-if="state==='active'"
          >Активна</span
          >
          <span
              class="close"
              v-if="state==='lastWeek'"
          >Скоро закінчиться</span
          >
          <span class="past" v-if="state==='past'">Минула</span>
        </p>
        <div v-html="content.period_shipment"></div>
      </div>
    </div>

    <div
        class="item-wrapper_new item-wrapper_new_desk"
        v-for="(key, value) in content.pairs"
        :key="value + '1'"
    >
      <div class="item item-left">
        <div class="img-wrapper">
          <img :src="`${$root.globalUrl}${key.image_left}`" alt/>
        </div>

        <div class="description">
          <div class="price-block-wrapper">
            <div class="number">
              <p class="big-num" v-html="key.quantity"></p>

              <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M0.667931 24.8366C0.472669 25.0318 0.472669 25.3484 0.667931 25.5437C0.863193 25.7389 1.17978 25.7389 1.37504 25.5437L0.667931 24.8366ZM24.7096 0.794939L0.667931 24.8366L1.37504 25.5437L25.4167 1.50205L24.7096 0.794939Z"
                    fill="white"
                />
                <path
                    d="M24.7089 25.5437C24.9042 25.7389 25.2208 25.7389 25.4161 25.5437C25.6113 25.3484 25.6113 25.0318 25.4161 24.8366L24.7089 25.5437ZM0.667314 1.50205L24.7089 25.5437L25.4161 24.8366L1.37442 0.794943L0.667314 1.50205Z"
                    fill="white"
                />
              </svg>
            </div>
            <div class="price-block">
              <div class="plus-icon">
                <svg
                    width="38"
                    height="38"
                    viewBox="0 0 38 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M36 19L2 19"
                      stroke="#545454"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                  <path
                      d="M19 2L19 36"
                      stroke="#545454"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  />
                </svg>
              </div>
              <p v-html="key.price_left"></p>
            </div>
          </div>

          <div class="name" v-html="key.title_left"></div>
        </div>
      </div>

      <div class="item item-right">
        <div class="img-wrapper">
          <img :src="`${$root.globalUrl}${key.image_right}`" alt/>
        </div>

        <div class="description">
          <div class="price-block-wrapper">
            <div class="price-block">
              <p v-html="key.price_right"></p>
            </div>

            <div class="number">
              <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M0.667931 24.8366C0.472669 25.0318 0.472669 25.3484 0.667931 25.5437C0.863193 25.7389 1.17978 25.7389 1.37504 25.5437L0.667931 24.8366ZM24.7096 0.794939L0.667931 24.8366L1.37504 25.5437L25.4167 1.50205L24.7096 0.794939Z"
                    fill="white"
                />
                <path
                    d="M24.7089 25.5437C24.9042 25.7389 25.2208 25.7389 25.4161 25.5437C25.6113 25.3484 25.6113 25.0318 25.4161 24.8366L24.7089 25.5437ZM0.667314 1.50205L24.7089 25.5437L25.4161 24.8366L1.37442 0.794943L0.667314 1.50205Z"
                    fill="white"
                />
              </svg>

              <p class="big-num" v-html="key.quantity_right"></p>
            </div>
          </div>

          <div class="name" v-html="key.title_right"></div>
        </div>
      </div>
    </div>

    <div
        class="item-wrapper_new item-wrapper_new_mob"
        v-for="(key, value) in content.pairs"
        :key="value + '2'"
    >
      <div class="img-wrapper">
        <img :src="`${$root.globalUrl}${key.image_left}`" alt/>

        <svg
            width="38"
            height="39"
            viewBox="0 0 38 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M36 19.1277L2 19.1277"
              stroke="#545454"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
          <path
              d="M19 2.12769L19 36.1277"
              stroke="#545454"
              stroke-width="4"
              stroke-linecap="round"
              stroke-linejoin="round"
          />
        </svg>

        <img :src="`${$root.globalUrl}${key.image_right}`" alt/>
      </div>

      <div class="description">
        <div class="triple-block">
          <div class="line1">
            <div class="text">
              <div class="big-num" v-html="key.quantity"></div>

              <div class="sep">
                <svg
                    width="13"
                    height="13"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M0.667931 24.8366C0.472669 25.0318 0.472669 25.3484 0.667931 25.5437C0.863193 25.7389 1.17978 25.7389 1.37504 25.5437L0.667931 24.8366ZM24.7096 0.794939L0.667931 24.8366L1.37504 25.5437L25.4167 1.50205L24.7096 0.794939Z"
                      fill="white"
                  />
                  <path
                      d="M24.7089 25.5437C24.9042 25.7389 25.2208 25.7389 25.4161 25.5437C25.6113 25.3484 25.6113 25.0318 25.4161 24.8366L24.7089 25.5437ZM0.667314 1.50205L24.7089 25.5437L25.4161 24.8366L1.37442 0.794943L0.667314 1.50205Z"
                      fill="white"
                  />
                </svg>
              </div>

              <p class="reg-text" v-html="key.title_left"></p>
            </div>

            <div class="separator">
              <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M1.00006 11.8569C0.723919 11.8569 0.500061 12.0807 0.500061 12.3569C0.500061 12.633 0.723919 12.8569 1.00006 12.8569L1.00006 11.8569ZM25.7139 11.8569L1.00006 11.8569L1.00006 12.8569L25.7139 12.8569L25.7139 11.8569Z"
                    fill="white"
                />
                <path
                    d="M12.8569 24.7138C12.8569 24.9899 13.0808 25.2138 13.3569 25.2138C13.6331 25.2138 13.8569 24.9899 13.8569 24.7138L12.8569 24.7138ZM12.8569 1.95106e-06L12.8569 24.7138L13.8569 24.7138L13.8569 1.86364e-06L12.8569 1.95106e-06Z"
                    fill="white"
                />
              </svg>
            </div>

            <div class="text">
              <div class="big-num" v-html="key.quantity_right"></div>

              <div class="sep">
                <svg
                    width="13"
                    height="13"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                      d="M0.667931 24.8366C0.472669 25.0318 0.472669 25.3484 0.667931 25.5437C0.863193 25.7389 1.17978 25.7389 1.37504 25.5437L0.667931 24.8366ZM24.7096 0.794939L0.667931 24.8366L1.37504 25.5437L25.4167 1.50205L24.7096 0.794939Z"
                      fill="white"
                  />
                  <path
                      d="M24.7089 25.5437C24.9042 25.7389 25.2208 25.7389 25.4161 25.5437C25.6113 25.3484 25.6113 25.0318 25.4161 24.8366L24.7089 25.5437ZM0.667314 1.50205L24.7089 25.5437L25.4161 24.8366L1.37442 0.794943L0.667314 1.50205Z"
                      fill="white"
                  />
                </svg>
              </div>

              <p class="reg-text" v-html="key.title_right"></p>
            </div>
          </div>

          <div class="line2">
            <div class="white-block white-block1" v-html="key.price_left"></div>

            <div
                class="white-block white-block2"
                v-html="key.price_right"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-calculate-drug-type2",
  props: {
    content: Object,
    startDate: String,
    endDate: String
  },
  data: () => ({
    timeStart: "",
    timeEnd: "",
    currentTime: "",
    oneWeek: null,
    state: "",
    totalPack: Number,
    currentPack: Number,
  }),
  mounted() {
    this.oneWeek = 14 * 24 * 60 * 60 * 1000;

    this.timeStart = new Date(this.startDate).getTime();
    this.timeEnd = new Date(this.endDate).getTime();
    this.currentTime = new Date().getTime();

    console.log(this.totalPack)
    console.log(this.currentPack)

    if (this.currentTime < this.timeStart) {
      this.state = "future";
    } else if (this.currentTime > this.timeEnd) {
      this.state = "past";
    } else if (this.currentTime > this.timeStart && this.currentTime + this.oneWeek < this.timeEnd) {
      this.state = "active";

      if (this.totalPack !== null || this.currentPack) {

        if (Math.floor((this.currentPack * 100) / this.totalPack) >= 30) {
          this.state = "active";
        } else if (Math.floor((this.currentPack * 100) / this.totalPack) < 30 &&
            Math.floor((this.currentPack * 100) / this.totalPack) >= 20) {
          this.state = "lastWeek";
        } else if (Math.floor((this.currentPack * 100) / this.totalPack) <= 19) {
          this.state = "past";
        }
      }

    } else if (this.currentTime > this.timeStart &&
        this.currentTime + this.oneWeek > this.timeEnd &&
        this.currentTime <= this.timeEnd) {
      this.state = "lastWeek";
      if (this.totalPack !== null || this.currentPack) {
        if (Math.floor((this.currentPack * 100) / this.totalPack) <= 19) {
          this.state = "past";
        }
      }
    }

  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-calculate-drug-type2 {
  margin: 15px 0;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  .double-block-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    @media screen and (max-width: 425px) {
      flex-direction: column;
    }

    > div {
      width: 100%;

      p {
        padding-top: 22px;
        font-size: 20px;
        font-family: $fontR;
        color: #545454;
        position: relative;

        @media screen and (max-width: 600px) {
          font-size: 16px;
          padding-top: 10px;
        }

        .future {
          font-family: $fontDemi;
        }

        .active {
          color: #00a03b;
          font-family: $fontDemi;
        }

        .close {
          color: #fb3449;
          font-family: $fontDemi;
        }

        .past {
          color: #9e9e9e;
          font-family: $fontDemi;
        }
      }

      &:first-child {
        margin-right: 35px;

        @media screen and (max-width: 600px) {
          margin-right: 15px;
        }
        @media screen and (max-width: 425px) {
          margin-right: 0;
          margin-bottom: 15px;
        }
      }

      &:last-child {
        margin-left: 35px;

        @media screen and (max-width: 600px) {
          margin-left: 15px;
        }
        @media screen and (max-width: 425px) {
          margin-left: 0;
        }
      }
    }
  }

  .item-wrapper_new {
    &.item-wrapper_new_desk {
      @media screen and (max-width: 600px) {
        display: none;
      }

      display: flex;
      margin-bottom: 15px;

      justify-content: space-between;

      .item {
        box-sizing: border-box;

        max-width: 400px;
        width: 100%;

        .img-wrapper {
          border-radius: 30px 30px 0 0;
        }

        .img-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px 20px 10px 20px;

          max-height: 250px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            display: block;
          }
        }

        .description {
          border-radius: 0 0 25px 25px;

          padding: 25px 0;

          display: flex;
          flex-direction: column;

          .price-block-wrapper {
            display: flex;
            align-items: center;

            .number {
              display: flex;
              align-items: center;

              .big-num {
                line-height: 0.8em;
                color: #ffffff;
                font-size: 80px;
                font-family: $fontL;
              }

              svg {
                margin: 0 15px;
              }
            }

            .price-block {
              padding: 10px 20px;
              background: #f2f2f2;
              position: relative;

              .plus-icon {
                position: absolute;
                right: -55px;
                top: 0;
                display: flex;
                align-items: center;
                height: 100%;
              }

              p {
                font-size: 18px;
                font-family: $fontDemi;
              }
            }
          }

          .name {
            margin-top: 15px;
            color: white;
            font-size: 16px;
            font-family: $fontDemi;
            padding: 0 20px;
          }
        }

        &.item-left {
          margin-right: 35px;

          .img-wrapper {
            border: 3px solid #00a03b;
          }

          .description {
            align-items: flex-end;
            background: #00a03b;

            .price-block {
              border-radius: 30px 0 0 30px;

              p {
                color: #00a03b;
              }
            }
          }
        }

        &.item-right {
          margin-left: 35px;

          .img-wrapper {
            border: 3px solid #aa198b;
          }

          .description {
            align-items: flex-start;
            background: #aa198b;

            .price-block {
              border-radius: 0 30px 30px 0;

              p {
                color: #aa198b;
              }
            }
          }
        }
      }
    }

    &.item-wrapper_new_mob {
      display: none;

      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
      }

      max-width: 385px;
      width: 100%;
      margin: 0 auto 15px auto;

      .img-wrapper {
        border: 1px solid #00a03b;
        border-radius: 20px 20px 0 0;

        padding: 20px 10px;

        svg {
          margin: 0 auto;
          display: block;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }

      .description {
        background: #00a03b;
        border-radius: 0 0 20px 20px;

        .triple-block {
          display: flex;
          flex-direction: column;

          padding: 16px 0 27px 0;

          .line1 {
            display: flex;
            justify-content: space-between;

            .separator {
              display: flex;
              align-items: center;
              margin: 0 15px;
            }

            .text {
              padding: 0 10px;
              display: flex;
              align-items: center;
              max-width: 200px;
              width: 100%;

              .big-num {
                font-size: 29px;
                font-family: $fontL;
                color: white;
              }

              .sep {
                display: flex;
                align-items: center;
                margin: 0 10px;
              }

              .reg-text {
                font-family: $fontR;
                color: white;
                font-size: 14px;
                word-break: break-all;
              }
            }
          }

          .line2 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .white-block {
              margin-top: 10px;
              background: #ffffff;
              padding: 5px 10px;

              &.white-block1 {
                border-radius: 0 20px 20px 0;
                color: #00a03b;
                font-size: 12px;
                font-family: $fontDemi;
              }

              &.white-block2 {
                border-radius: 20px 0 0 20px;
                color: #aa198b;
                font-size: 12px;
                font-family: $fontDemi;
                margin: 10px 0 0 auto;
              }
            }
          }
        }
      }
    }
  }
}
</style>
