<template>
  <div class="section-row-col-text">
    <div v-for="(key, value) in dataContent" :key="value">
      <div
        class="text-wrapper"
        v-for="(secondKey, value2) in key"
        :key="value2"
      >
        <p>
          <b v-html="secondKey.text.title"></b>
          <span v-html="secondKey.text.description"></span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-col-text",
  props: {
    content: Object
  },
  data: () => ({
    dataContent: []
  }),
  beforeMount() {
    let array = [];
    this.content.blocks.forEach((el, index) => {
      array.push({
        text: el,
        num: index + 1
      });
    });
    let size = Math.ceil(array.length / 2);
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      this.dataContent[i] = array.slice(i * size, i * size + size);
    }
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-row-col-text {
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  padding-top: 25px;
  position: relative;
  flex-direction: row;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
    flex-direction: column;
    padding-top: 15px;
  }

  &:after {
    content: "";
    width: 41px;
    height: 3px;
    background: #00a03b;
    position: absolute;
    left: 0;
    top: 0;
  }

  > div {
    &:nth-child(1) {
      margin-right: 15px;
      @media screen and (max-width: 670px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 0;
      }
    }

    .text-wrapper {
      width: 100%;
      max-width: 400px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }

      @media screen and (max-width: 670px) {
        width: 100%;
        max-width: 670px;
        &:first-child {
          margin-bottom: 15px;
        }
      }

      p {
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          font-size: 14px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        b,
        strong {
          display: block;
          color: #00a03b;
          font-family: $fontDemi;
          font-weight: normal;
          font-size: 16px;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
