<template>
  <div class="section-main-img">
    <img class="main-img" :src="`${$root.globalUrl}${content.file}`" alt="" />
  </div>
</template>

<script>
export default {
  name: "section-main-img",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "image_full_width"
  })
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-main-img {
  margin: 15px 0;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 15px -15px;
  }

  .main-img {
    width: 100%;
    height: auto;
    display: block;
  }
}
</style>
