<template>
  <div class="section-study-design">
    <div class="double-wrapper">
      <div class="left">
        <h2 class="h2-title-study" v-html="content.title"></h2>

        <div class="item" v-for="(key, value) in content.blocks" :key="value">
          <h3 v-html="key.title"></h3>
          <div class="text-wrapper" v-html="key.description"></div>
        </div>
      </div>

      <div class="right">
        <div class="img-wrapper">
          <img :src="`${$root.globalUrl}${content.file}`" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-study-design",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "image_right_paragraph_left"
  })
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-study-design {
  margin: 15px 0;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  .double-wrapper {
    display: flex;

    @media screen and (max-width: 425px) {
      flex-direction: column-reverse;
    }

    > div {
      max-width: 400px;
      width: 100%;

      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }

      .h2-title-study {
        color: #00a03b;
        font-family: $fontDemi;
        font-size: 30px;
        font-weight: normal;

        padding-bottom: 25px;
        margin-bottom: 25px;
        position: relative;

        @media screen and (max-width: 670px) {
          font-size: 18px;
        }

        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 41px;
          height: 3px;
          background: #00a03b;
        }
      }

      img {
        width: 100%;
        height: auto;
        display: block;
        @media screen and (max-width: 425px) {
          margin-bottom: 15px;
        }
      }

      .item {
        margin-bottom: 30px;
        @media screen and (max-width: 425px) {
          margin-bottom: 15px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        h3 {
          color: #00a03b;
          font-family: $fontDemi;
          font-size: 16px;
          line-height: 1.3em;
          margin-bottom: 15px;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }
        }

        ::v-deep p {
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
          margin-bottom: 15px;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }

          b,
          strong {
            font-family: $fontDemi;
            font-weight: normal;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &:nth-child(1) {
        margin-right: 15px;

        @media screen and (max-width: 670px) {
          margin-right: 5px;
        }
        @media screen and (max-width: 425px) {
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        margin-left: 15px;

        @media screen and (max-width: 670px) {
          margin-left: 5px;
        }
        @media screen and (max-width: 425px) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
