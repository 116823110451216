<template>
  <div class="section-download">
    <h2 class="h3-title-chart" v-html="content.title"></h2>

    <div class="img-wrapper">
      <img :src="`${$root.globalUrl}${content.image}`" alt="" />
    </div>

    <a
      download="img"
      class="download-btn"
      :href="`${$root.globalUrl}${content.file}`"
      target="_blank"
    >
      <div>
        <svg
          width="13"
          height="17"
          viewBox="0 0 13 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.96875 5.84375H10.2969C10.6491 5.84375 10.9869 5.98368 11.236 6.23275C11.4851 6.48182 11.625 6.81963 11.625 7.17188V14.0781C11.625 14.4304 11.4851 14.7682 11.236 15.0173C10.9869 15.2663 10.6491 15.4062 10.2969 15.4062H2.32812C1.97588 15.4063 1.63807 15.2663 1.389 15.0173C1.13993 14.7682 1 14.4304 1 14.0781V7.17188C1 6.81963 1.13993 6.48182 1.389 6.23275C1.63807 5.98368 1.97588 5.84375 2.32812 5.84375H3.65625"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.65625 9.03125L6.3125 11.6875L8.96875 9.03125"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M6.3125 1.59375V11.1562"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <p v-html="content.filename"></p>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "section-download",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "download_example"
  })
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-download {
  margin: 15px 0;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  .h3-title-chart {
    color: #00a03b;
    font-family: $fontDemi;
    font-size: 30px;
    margin-bottom: 15px;
    @media screen and (max-width: 670px) {
      font-size: 18px;
    }
  }

  .img-wrapper {
    width: 100%;
    margin-bottom: 15px;

    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }

  .download-btn {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    text-decoration: none;
    max-width: 189px;
    width: 100%;
    margin: 0 0 0 auto;

    @media screen and (max-width: 425px) {
      margin: 0 auto;
    }

    > div {
      padding: 10px;
      max-width: 189px;
      width: 100%;
      height: 35px;
      background: #aa198b;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;

      p {
        margin-left: 10px;
        font-size: 9px;
        font-family: $fontR;
        color: white;
      }
    }
  }
}
</style>
