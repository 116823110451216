<template>
  <div class="section-ref">
    <h3 v-html="content.title"></h3>

    <div class="list-note">
      <p v-html="content.description"></p>
    </div>

    <div class="list-link">
      <div v-for="(key, value) in content.blocks" :key="value">
        <span v-html="key.number"></span>
        <p v-html="key.description"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-ref",

  props: {
    content: Object
  },

  data: () => ({
    nameSection: "reg_list"
  })
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-ref {
  margin: 15px 0;
  padding: 30px 45px;
  background: #ededed;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  @media screen and (max-width: 425px) {
    padding: 20px 15px;
    margin: 15px -15px;
  }

  h3 {
    font-family: $fontM;
    font-size: 20px;
    color: #00a03b;
    font-weight: normal;
    margin-bottom: 25px;
  }

  .list-link {
    > div {
      margin-bottom: 20px;
      display: flex;

      p {
        display: flex;
        align-items: center;
        color: #545454;
        font-family: $fontR;
        font-size: 13px;
      }

      span {
        margin-right: 15px;
        min-width: 25px;
        min-height: 25px;
        max-height: 25px;
        padding: 0 5px;
        background: #00a03b;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 13px;
        font-family: $fontR;
      }
    }
  }

  .list-note {
    p {
      color: #545454;
      font-family: $fontR;
      font-size: 13px;
      margin-bottom: 15px;
    }
  }
}
</style>
