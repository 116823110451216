<template>
  <div class="section-row-icon-text">
    <template v-for="(key, value) in content.blocks">
      <div
        class="item-icon"
        :key="value"
        v-if="key.text !== '' && key.file !== undefined"
      >
        <div>
          <img
            :src="`${$root.globalUrl}${key.file}`"
            alt=""
            width="45"
            height="45"
          />
        </div>
        <div class="text-wrapper" v-html="key.text"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "section-row-icon-text",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-row-icon-text {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin: 15px 0;

  @media screen and (max-width: 1024px) {
    justify-content: center;
  }

  @media screen and (max-width: 670px) {
    margin: 15px 0;
    flex-flow: row wrap;
    justify-content: center;
  }

  .item-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 150px;
    margin: 15px;
    width: 100%;

    @media screen and (max-width: 425px) {
      margin: 10px;
      max-width: 125px;
    }

    img {
      display: block;
      margin-bottom: 15px;
      height: 45px;
      width: auto;
    }

    p {
      text-align: center;
      font-family: $fontM;
      font-size: 13px;
      color: #00a03b;
    }
  }
}
</style>
