<template>
  <div id="app">
    <div v-if="isIE === true" class="ie-block">
      <div class="ie-content">
        <headed-block></headed-block>
        <div class="h1-title">Ваш браузер застарів!</div>

        <div class="double-text">
          <div>
            <div class="text1">
              Ви користуєтеся застарілою версією браузера
              <b>Internet Explorer.</b>
            </div>
          </div>
          <div>
            <div class="text2">
              Вона не підтримує багато сучасних технологій, через що
              веб-сторінки часто будуть відображатися неправильно і не всі
              функції сайтів будуть вам доступні. Тому пропонуємо до вашої уваги
              сучасніші браузери. Усі вони безкоштовні, легко встановлюються і
              прості у користуванні. У разі переходу на будь-який із перелічених
              нижче браузерів усі ваші закладки і паролі будуть перенесені з
              поточного браузера у новий – ви нічого не втратите.
            </div>
          </div>
        </div>

        <div class="br-list">
          <div class="item">
            <div class="img">
              <img :src="require('./assets/img/chrome.png')" alt />
            </div>
            <div class="name">Google Chrome</div>
            <div class="text">
              Найпопулярніший на сьогодні браузер від гіганта пошукової
              індустрії компанії Google. Має простий та зручний інтерфейс і
              багато розширень.
            </div>
            <a
              href="https://www.google.com/intl/ru/chrome/"
              target="_blank"
              class="link"
              >Завантажити Google Chrome</a
            >
          </div>
          <div class="item">
            <div class="img">
              <img :src="require('./assets/img/fire.png')" alt />
            </div>
            <div class="name">Firefox</div>
            <div class="text">
              Один з найпоширеніших і гнучкіших браузерів. Браузер можна
              налаштований на будь-який смак за допомогою величезної кількості
              доповнень на всі випадки життя і тем оформлення, які можна знайти
              на офіційному сайті доповнень.
            </div>
            <a
              href="https://www.mozilla.org/ru/firefox/new/"
              target="_blank"
              class="link"
              >Завантажити Firefoх</a
            >
          </div>
          <div class="item">
            <div class="img">
              <img :src="require('./assets/img/opera.png')" alt />
            </div>
            <div class="name">Opera</div>
            <div class="text">
              Цей браузер завжди позиціонувався, як дуже зручний і швидкий. Має
              внутрішні утиліти для прискорення завантаження сторінок, що
              особливо актуально для користувачів із повільним інтернетом.
            </div>
            <a
              href="https://www.opera.com/ru/download"
              target="_blank"
              class="link"
              >Завантажити Ореrа</a
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="isIE === false" id="main-main-wrapper">
      <router-view />
    </div>

    <transition name="fade">
      <div class="popup-wrapper" v-if="checkPopUp === true && isIE === false">
        <div class="popup-block">
          <p v-if="ifNo === false" class="h1-title">
            Чи є ви спеціалістом
            <br />з охорони здоров’я?
          </p>
          <p v-if="ifNo === true" class="h1-title h1-title-no">
            Вибачте, будь ласка, інформація призначена лише для спеціалістів
            охорони здоров’я
          </p>

          <div class="btn-wrapper" v-if="ifNo === false">
            <div class="yes" @click="closePopUp()">
              <p>Так</p>
            </div>
            <div class="no" @click="noText()">
              <p>Ні</p>
            </div>
          </div>

          <div
            class="btn-wrapper"
            v-if="ifNo === true"
            @click="reloadPagePopup()"
          >
            <div class="yes">
              <p>Добре</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
const { detect } = require("detect-browser");
const browser = detect();
export default {
  name: "app",
  data: () => ({
    activeClass: "active",
    mobile: false,
    checkPopUp: false,
    ifNo: false,
    isIE: false
  }),
  methods: {
    reloadPagePopup() {
      this.$router.go();
    },
    noText() {
      this.ifNo = true;
    },
    closePopUp() {
      sessionStorage.setItem("popupCheck", "true");
      this.checkPopUp = false;
    },
    axiosApiUrl() {
      let URLPATH = "";
      if (location.hostname.includes("localhost")) {
        URLPATH = "https://rx.teva.ua";
        // URLPATH = "http://13.74.250.32";
      } else if (
        window.location.origin === "http://teva-distributors.azurewebsites.net"
      ) {
        URLPATH = "http://13.74.250.32";
      } else {
        URLPATH = "https://rx.teva.ua";
      }
      return URLPATH;
    }
  },
  created() {
    if (browser.name === "ie") {
      this.isIE = true;
      sessionStorage.setItem("ie", "true");
    }
    this.$root.globalUrl = this.axiosApiUrl();
  },
  mounted() {
    this.$nextTick(() => {
      if (sessionStorage.getItem("popupCheck") === null) {
        this.checkPopUp = true;
      }

      let ua = navigator.userAgent.toLowerCase();
      let isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      let iOS =
        !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

      if (isAndroid || iOS === true) {
        this.mobile = true;
      }
    });
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";

.ie-block {
  width: 100%;
  min-height: 100vh;

  background: url("assets/img/ie-bg.png") no-repeat center bottom;
  background-size: contain;

  .ie-content {
    margin: 0 auto;
    max-width: 1100px;
    padding: 100px 15px 0 15px;

    .h1-title {
      color: #00a03b;
      font-family: $fontDemi;
      font-size: 40px;
      margin-bottom: 75px;
    }

    .double-text {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;

      > div {
        .text1 {
          color: #00a03b;
          font-family: $fontR;
          font-size: 30px;

          b {
            font-family: $fontDemi;
          }
        }

        .text2 {
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
        }

        &:first-child {
          margin-right: 15px;
          max-width: 400px;
          width: 100%;
        }

        &:last-child {
          margin-left: 15px;
          max-width: 650px;
          width: 100%;
        }
      }
    }

    .br-list {
      padding-top: 70px;
      position: relative;
      display: flex;
      justify-content: space-between;

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 40px;
        height: 3px;
        background: #00a03b;
      }

      .item {
        margin-right: 20px;
        max-width: 354px;
        width: 100%;

        .img {
          max-width: 80px;
          width: 100%;

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }

        .name {
          margin-top: 32px;
          color: #00a03b;
          font-size: 20px;
          font-family: $fontM;
        }

        .text {
          margin-top: 15px;
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
        }

        .link {
          display: inline-block;
          margin-top: 24px;
          font-family: $fontDemi;
          font-size: 16px;
          color: #00a03b;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.popup-wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(53, 120, 110, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  .popup-block {
    background: white;
    max-width: 419px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    flex-direction: column;
    box-sizing: border-box;

    @media screen and (max-width: 550px) {
      padding: 20px;
    }

    * {
      user-select: none;
    }

    .h1-title {
      color: #317575;
      font-family: $fontDemi;
      font-size: 20px;
      text-align: center;
      @media screen and (max-width: 550px) {
        font-size: 15px;
      }
    }

    .h1-title-no {
      text-align: center;
      font-size: 20px;
    }

    .btn-wrapper {
      margin-top: 40px;
      display: flex;
      @media screen and (max-width: 550px) {
        margin-top: 20px;
      }

      .yes {
        cursor: pointer;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 118px;
        height: 42px;
        background: #317575;
        border-radius: 100px;

        @media screen and (max-width: 550px) {
          width: 90px;
          height: 40px;
          margin-right: 10px;
        }

        p {
          color: white;
          font-size: 15px;
          font-family: $fontDemi;
          @media screen and (max-width: 550px) {
            font-size: 12px;
          }
        }
      }

      .no {
        cursor: pointer;
        margin-left: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 118px;
        height: 42px;
        border-radius: 100px;
        border: 2px solid #317575;

        @media screen and (max-width: 550px) {
          width: 90px;
          height: 40px;
          margin-right: 10px;
        }

        p {
          color: #317575;
          font-size: 15px;
          font-family: $fontDemi;
          @media screen and (max-width: 550px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

* {
  scroll-behavior: smooth;
  user-select: none;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $fontR;
}

* sub,
* sup {
  font-size: 60%;
  line-height: 0;
}

@font-face {
  font-family: "AvenirNextCyr-Bold";
  src: url("assets/fonts/AvenirNextCyr-Bold.woff2") format("woff2"),
    url("assets/fonts/AvenirNextCyr-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr-Medium";
  src: url("assets/fonts/AvenirNextCyr-Medium.woff2") format("woff2"),
    url("assets/fonts/AvenirNextCyr-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr-Regular";
  src: url("assets/fonts/AvenirNextCyr-Regular.woff2") format("woff2"),
    url("assets/fonts/AvenirNextCyr-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr-Light";
  src: url("assets/fonts/AvenirNextCyr-Light.woff2") format("woff2"),
    url("assets/fonts/AvenirNextCyr-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr-Thin";
  src: url("assets/fonts/AvenirNextCyr-Thin.woff2") format("woff2"),
    url("assets/fonts/AvenirNextCyr-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr-Demi";
  src: url("assets/fonts/AvenirNextCyr-Demi.woff2") format("woff2"),
    url("assets/fonts/AvenirNextCyr-Demi.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr-UltraLight";
  src: url("assets/fonts/AvenirNextCyr-UltraLight.woff2") format("woff2"),
    url("assets/fonts/AvenirNextCyr-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-LightItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-LightItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-LightItalic.woff') format('woff');*/
/*    font-weight: 300;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-BoldItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-BoldItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-BoldItalic.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-Heavy';*/
/*    src: url('assets/fonts/AvenirNextCyr-Heavy.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-Heavy.woff') format('woff');*/
/*    font-weight: 900;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-LightItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-LightItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-LightItalic.woff') format('woff');*/
/*    font-weight: 300;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-HeavyItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-HeavyItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-HeavyItalic.woff') format('woff');*/
/*    font-weight: 900;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-BoldItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-BoldItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-BoldItalic.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-UltraLightIt';*/
/*    src: url('assets/fonts/AvenirNextCyr-UltraLightIt.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-UltraLightIt.woff') format('woff');*/
/*    font-weight: 200;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-UltraLightIt';*/
/*    src: url('assets/fonts/AvenirNextCyr-UltraLightIt.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-UltraLightIt.woff') format('woff');*/
/*    font-weight: 200;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-Italic';*/
/*    src: url('assets/fonts/AvenirNextCyr-Italic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-Italic.woff') format('woff');*/
/*    font-weight: normal;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-DemiItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-DemiItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-DemiItalic.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-Demi';*/
/*    src: url('assets/fonts/AvenirNextCyr-Demi.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-Demi.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-DemiItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-DemiItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-DemiItalic.woff') format('woff');*/
/*    font-weight: bold;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-HeavyItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-HeavyItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-HeavyItalic.woff') format('woff');*/
/*    font-weight: 900;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-Italic';*/
/*    src: url('assets/fonts/AvenirNextCyr-Italic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-Italic.woff') format('woff');*/
/*    font-weight: normal;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-MediumItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-MediumItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-MediumItalic.woff') format('woff');*/
/*    font-weight: 500;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-MediumItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-MediumItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-MediumItalic.woff') format('woff');*/
/*    font-weight: 500;*/
/*    font-style: italic;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-Heavy';*/
/*    src: url('assets/fonts/AvenirNextCyr-Heavy.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-Heavy.woff') format('woff');*/
/*    font-weight: 900;*/
/*    font-style: normal;*/
/*}*/

/*@font-face {*/
/*    font-family: 'AvenirNextCyr-ThinItalic';*/
/*    src: url('assets/fonts/AvenirNextCyr-ThinItalic.woff2') format('woff2'),*/
/*    url('assets/fonts/AvenirNextCyr-ThinItalic.woff') format('woff');*/
/*    font-weight: 100;*/
/*    font-style: italic;*/
/*}*/

$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
#app {
}
</style>
