<template>
  <div class="article-page">
    <headed-block></headed-block>

    <content-page-parallax></content-page-parallax>

    <div class="content">
      <div class="article-wrapper">
        <h3
            class="h1-title"
            v-html="data.description"
            v-if="data.description !== ''"
        ></h3>

        <template v-for="key in content">
          <section-full-chart
              v-if="key.type === 'text_image_description'"
              :content="key"
              :key="key.type"
          ></section-full-chart>

          <section-video-360
              v-if="key.type === 'video_360'"
              :content="key"
              :key="key.type"
          ></section-video-360>

          <section-reg-text
              v-if="key.type === 'text_full_width'"
              :content="key"
              :key="key.type"
          ></section-reg-text>

          <section-ref
              v-if="key.type === 'reg_list'"
              :content="key"
              :key="key.type"
          ></section-ref>

          <section-col-list-text
              v-if="key.type === 'big_list'"
              :content="key"
              :key="key.type"
          ></section-col-list-text>

          <section-background-text-img
              v-if="key.type === 'text_full_width_with_background'"
              :content="key"
              :key="key.type"
          ></section-background-text-img>

          <section-double-row-text
              v-if="key.type === 'text_with_title_horizontal'"
              :content="key"
              :key="key.type"
          ></section-double-row-text>

          <section-double-col-text
              v-if="key.type === 'text_with_title_vertical'"
              :content="key"
              :key="key.type"
          ></section-double-col-text>

          <section-main-img
              v-if="key.type === 'image_full_width'"
              :content="key"
              :key="key.type"
          ></section-main-img>

          <section-diagram-type1
              v-if="key.type === 'diagram_type_1'"
              :content="key"
              :key="key.type"
          ></section-diagram-type1>

          <section-download
              v-if="key.type === 'download_example'"
              :content="key"
              :key="key.type"
          ></section-download>

          <section-image_left_text_with_background_right
              v-if="key.type === 'image_left_text_with_background_right'"
              :content="key"
              :key="key.type"
          ></section-image_left_text_with_background_right>

          <section-image_right_text_with_background_left
              v-if="key.type === 'image_right_text_with_background_left'"
              :content="key"
              :key="key.type"
          ></section-image_right_text_with_background_left>

          <section-recommend
              v-if="key.type === 'two_icons_with_background_text_right'"
              :content="key"
              :key="key.type"
          ></section-recommend>

          <section-row-double-icon-background-text
              v-if="key.type === 'two_icons_with_background_text'"
              :content="key"
              :key="key.type"
          ></section-row-double-icon-background-text>

          <section-row-icon-background-text
              v-if="key.type === 'four_icons_with_background_text'"
              :content="key"
              :key="key.type"
          ></section-row-icon-background-text>

          <download-app
              v-if="key.type === 'download_app'"
              :content="key"
              :key="key.type"
          ></download-app>

          <section-row-drug-use
              v-if="key.type === 'image_left_paragraph_right'"
              :content="key"
              :key="key.type"
          ></section-row-drug-use>

          <section-study-design
              v-if="key.type === 'image_right_paragraph_left'"
              :content="key"
              :key="key.type"
          ></section-study-design>

          <section-picker
              v-if="key.type === 'photo_picker'"
              :content="key"
              :key="key.type"
          ></section-picker>

          <section-single-video
              v-if="key.type === 'single_video'"
              :content="key"
              :key="key.type"
          ></section-single-video>

          <section-video
              v-if="
              key.type === 'slider_with_video' ||
                key.type === 'slider_with_youtube_video'
            "
              :content="key"
              :key="key.type"
          ></section-video>

          <section-slider-wrapper
              v-if="key.type === 'image_full_width_slider'"
              :content="key"
              :key="key.type"
          ></section-slider-wrapper>

          <section-drug-description
              v-if="
              key.type === 'item_preview_title' ||
                key.type === 'item_preview_content' ||
                key.type === 'item_preview_dosing' ||
                key.type === 'item_preview_instruction' ||
                key.type === 'button_concent'
            "
              :content="key"
              :key="key.type"
          ></section-drug-description>

          <section-table
              v-if="key.type === 'table_with_percents'"
              :content="key"
              :key="key.type"
          ></section-table>

          <section-diagram-type2
              v-if="key.type === 'diagram_type_2'"
              :content="key"
              :key="key.type"
          ></section-diagram-type2>

          <section-row-img-text
              v-if="key.type === 'title_description_image_vertical'"
              :content="key"
              :key="key.type"
          ></section-row-img-text>

          <section-text-carousel
              v-if="key.type === 'slider_with_image_right'"
              :content="key"
              :key="key.type"
          ></section-text-carousel>

          <section-text-big-list
              v-if="key.type === 'big_list_right'"
              :content="key"
              :key="key.type"
          ></section-text-big-list>

          <section-col-img-text
              v-if="key.type === 'title_description_image_horizontal'"
              :content="key"
              :key="key.type"
          ></section-col-img-text>

          <section-row-text-img
              v-if="key.type === 'title_description_image_right'"
              :content="key"
              :key="key.type"
          ></section-row-text-img>

          <section-blockquote-list
              v-if="
              key.type === 'list_with_points' ||
                key.type === 'quote_full_width' ||
                key.type === 'list_with_points_two'
            "
              :content="key"
              :key="key.type"
          ></section-blockquote-list>

          <section-list
              v-if="key.type === 'list_with_underline'"
              :content="key"
              :key="key.type"
          ></section-list>

          <section-list-text
              v-if="
              key.type === 'list_with_circle_number' ||
                key.type === 'list_with_circle_number_two'
            "
              :content="key"
              :key="key.type"
          ></section-list-text>

          <section-app-carousel
              v-if="key.type === 'phone_slider'"
              :content="key"
              :key="key.type"
          ></section-app-carousel>

          <section-row-text-padding
              v-if="key.type === 'text_passage_black'"
              :content="key"
              :key="key.type"
          ></section-row-text-padding>

          <section-row-list-text
              v-if="key.type === 'big_list_2_columns'"
              :content="key"
              :key="key.type"
          ></section-row-list-text>

          <section-row-col-text
              v-if="key.type === 'text_passage'"
              :content="key"
              :key="key.type"
          ></section-row-col-text>

          <section-row-blockquote
              v-if="key.type === 'quote_text'"
              :content="key"
              :key="key.type"
          ></section-row-blockquote>

          <section-double-list
              v-if="key.type === 'text_passage_underline'"
              :content="key"
              :key="key.type"
          ></section-double-list>

          <section-double-row-text-icon
              v-if="key.type === 'icons_with_text_horizontal'"
              :content="key"
              :key="key.type"
          ></section-double-row-text-icon>

          <section-double-col-text-icon
              v-if="key.type === 'icons_with_text_vertical'"
              :content="key"
              :key="key.type"
          ></section-double-col-text-icon>

          <section-row-icon-text
              v-if="key.type === 'icons_without_background'"
              :content="key"
              :key="key.type"
          ></section-row-icon-text>

          <section-question
              v-if="key.type === 'poll'"
              :content="key"
              :key="key.type"
          ></section-question>

          <!--distributor-->

          <!--first_template-->
          <section-calculate-drug
              v-if="key.type === 'first_template'"
              :content="key"
              :key="key.type + '_' + '1'"
              :startDate="data.promotionStart"
              :endDate="data.promotionEnd"
              :totalPack="data.promotionQty"
              :current-pack="data.drugRemainsCount"
          ></section-calculate-drug>

          <section-stocks-description
              v-if="key.type === 'first_template'"
              :content="key"
              :key="key.type + '_' + '2'"
          ></section-stocks-description>

          <section-table-drug-comparison
              v-if="key.type === 'first_template'"
              :content="key"
              :key="key.type + '_' + '3'"
          ></section-table-drug-comparison>

          <section-pharmacy-info
              v-if="key.type === 'first_template'"
              :content="defaultData"
              :key="key.type + '_' + '4'"
          ></section-pharmacy-info>

          <!--first_template-end-->

          <!--second_template-->
          <section-calculate-drug-type2
              v-if="key.type === 'second_template'"
              :startDate="data.promotionStart"
              :endDate="data.promotionEnd"
              :content="key"
              :key="key.type + '_' + '1'"
              :totalPack="data.promotionQty"
              :current-pack="data.drugRemainsCount"
          ></section-calculate-drug-type2>

          <section-stocks-description
              v-if="key.type === 'second_template'"
              :content="key"
              :key="key.type + '_' + '2'"
          ></section-stocks-description>

          <section-table-drug-comparison
              v-if="key.type === 'second_template'"
              :content="key"
              :key="key.type + '_' + '3'"
          ></section-table-drug-comparison>

          <section-pharmacy-info
              v-if="key.type === 'second_template'"
              :content="defaultData"
              :key="key.type + '_' + '4'"
          ></section-pharmacy-info>

          <webinar_room
              v-if="key.type === 'webinar_room'"
              :content="key"
              :key="key.type"
          ></webinar_room>
          <!--second_template-end-->

          <!--distributor-end-->
        </template>

        <!-- Begin drug remains -->
        <template v-if="remainsForTable.length !== 0">
          <div class="drug-remains__title">Залишки по препарату:</div>
          <div class="drug-remains" v-for="(drugs, key) in remainsForTable" :key="key" v-if="drugs.length !== 0">
            <div class="drug-remains__table">
              <div class="table-head">
                <div class="table-head__row">
                  <div class="table-head__item">
                    Склад (місто)
                  </div>
                  <div class="table-head__item">
                    Бренд
                  </div>
                  <div class="table-head__item">
                    Препарат
                  </div>
                  <div class="table-head__item">
                    Кількість
                  </div>
                </div>
              </div>
              <div class="table">
                <template v-for="(drug, key) in drugs">
                  <div class="table__row" :key="key">
                    <div class="table__col">
                      <strong>Склад (місто):</strong> {{ drug.city }}
                    </div>
                    <div class="table__col">
                      <strong>Бренд:</strong> {{ drug.brand }}
                    </div>
                    <div class="table__col">
                      <strong>Препарат:</strong> {{ drug.drugName }}
                    </div>
                    <div class="table__col">
                      <strong>Кількість:</strong> {{ drug.quantity }}
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <!-- // -->

        <div
            class="promo-main"
            v-html="data.promoNumber"
            v-if="data.promoNumber !== null"
        ></div>

        <div class="more-article-wrapper more-article-wrapper-mobile">
          <div @click="backBtn()" class="back-btn">
            <p>До каталогу</p>
            <span>
              <svg
                  width="31"
                  height="15"
                  viewBox="0 0 31 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M29.4727 7.30823L5.84907 7.30823"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                />
                <path
                    d="M8.61914 13.0408L3.07295 7.27037L8.61914 1.49997"
                    stroke="white"
                    stroke-width="3"
                    stroke-linecap="round"
                />
              </svg>
            </span>
          </div>

          <div class="more-article" v-if="relatedPage !== null">
            <h2 class="h2-title-more">Вас може зацікавити:</h2>

            <div class="articles-wrapper">
              <div
                  v-for="(key, value) in relatedPage"
                  class="article-item one-section"
                  @click="goToArticle(key.slug)"
                  v-bind:class="[
                  { color1: key.marker === 'past' },
                  { color2: key.marker === 'active' },
                  { color3: key.marker === 'lastWeek' },
                  { color4: key.marker === 'future' }
                ]"
                  :key="value"
              >
                <div class="img-wrapper">
                  <img
                      class="article-img mob"
                      :src="
                      `${$root.globalUrl}/uploads/title/${key.titlePictureMobile}`
                    "
                      alt
                  />

                  <img
                      class="article-img desk"
                      :src="
                      `${$root.globalUrl}/uploads/title/${key.titlePicture}`
                    "
                      alt
                  />
                </div>

                <div class="description">
                  <div class="text-wrapper">
                    <p class="h2-title" v-html="key.shortTitle"></p>
                    <p class="h3-title" v-html="key.shortDescription"></p>
                  </div>

                  <div class="bottom-wrapper bottom-wrapper1">
                    <div class="marker">
                      <div class="icon">
                        <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                          <path
                              d="M14.6667 24.5143H18.3333C18.3333 25.0144 18.1402 25.4941 17.7964 25.8477C17.4525 26.2014 16.9862 26.4 16.5 26.4C16.0138 26.4 15.5475 26.2014 15.2036 25.8477C14.8598 25.4941 14.6667 25.0144 14.6667 24.5143ZM24.75 22.6286V23.5715H8.25V22.6286L10.0833 20.7429V15.0858C10.0833 12.1629 11.9442 9.58889 14.6667 8.75918V8.48575C14.6667 7.98563 14.8598 7.50599 15.2036 7.15235C15.5475 6.79871 16.0138 6.60004 16.5 6.60004C16.9862 6.60004 17.4525 6.79871 17.7964 7.15235C18.1402 7.50599 18.3333 7.98563 18.3333 8.48575V8.75918C21.0558 9.58889 22.9167 12.1629 22.9167 15.0858V20.7429L24.75 22.6286ZM19.25 13.2H13.75V15.0858H16.72L13.75 17.6315V19.8H19.25V17.9143H16.28L19.25 15.3686V13.2Z"
                              fill="#9E9E9E"
                          />
                        </svg>
                      </div>
                      <div class="text">Минула акція</div>
                    </div>

                    <div class="more-btn">
                      <p>Детальніше</p>
                    </div>
                  </div>

                  <div class="bottom-wrapper bottom-wrapper2">
                    <div class="marker">
                      <div class="icon">
                        <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                          <path
                              d="M16.5 8C11.8044 8 8 11.8044 8 16.5C8 21.1956 11.8044 25 16.5 25C21.1956 25 25 21.1956 25 16.5C25 11.8044 21.1956 8 16.5 8ZM20.4655 17.3226L14.4333 20.7843C13.8917 21.0859 13.2097 20.6986 13.2097 20.0645V12.9355C13.2097 12.3048 13.8883 11.9141 14.4333 12.2157L20.4655 15.8831C21.0276 16.1984 21.0276 17.0107 20.4655 17.3226Z"
                              fill="#00A03B"
                          />
                        </svg>
                      </div>
                      <div class="text">Активна акція</div>
                    </div>

                    <div class="more-btn">
                      <p>Детальніше</p>
                    </div>
                  </div>

                  <div class="bottom-wrapper bottom-wrapper3">
                    <div class="marker">
                      <div class="icon">
                        <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                          <path
                              d="M26 22.904C26 23.3252 25.8518 23.6465 25.5553 23.8679C25.2588 24.0893 24.8949 24.2 24.4636 24.2H9.53639C9.10512 24.2 8.74124 24.0866 8.44474 23.8598C8.14825 23.633 8 23.3144 8 22.904C8 22.58 8.09704 22.2506 8.29111 21.9158L15.7466 8.891C16.1024 8.297 16.5229 8 17.0081 8C17.4933 8 17.903 8.297 18.2372 8.891L25.7089 21.932C25.903 22.2776 26 22.6016 26 22.904ZM18.124 14.6258V12.2606H15.876V14.6258C15.876 14.777 15.8868 14.9201 15.9084 15.0551C15.9299 15.1901 15.9596 15.3413 15.9973 15.5087C16.035 15.6761 16.0647 15.8192 16.0863 15.938L16.5067 18.5624H17.4609L17.8976 15.938C17.9191 15.83 17.9515 15.6896 17.9946 15.5168C18.0377 15.344 18.0701 15.1901 18.0916 15.0551C18.1132 14.9201 18.124 14.777 18.124 14.6258ZM18.124 20.8952C18.124 20.582 18.0135 20.3174 17.7925 20.1014C17.5714 19.8854 17.3046 19.7774 16.9919 19.7774C16.69 19.7774 16.4286 19.8854 16.2075 20.1014C15.9865 20.3174 15.876 20.582 15.876 20.8952C15.876 21.2084 15.9865 21.4757 16.2075 21.6971C16.4286 21.9185 16.69 22.0292 16.9919 22.0292C17.3046 22.0292 17.5714 21.9185 17.7925 21.6971C18.0135 21.4757 18.124 21.2084 18.124 20.8952Z"
                              fill="#FB3449"
                          />
                        </svg>
                      </div>
                      <div class="text">
                        Скоро закінчиться
                      </div>
                    </div>

                    <div class="more-btn">
                      <p>Детальніше</p>
                    </div>
                  </div>

                  <div class="bottom-wrapper bottom-wrapper4">
                    <div class="marker">
                      <div class="icon">
                        <svg
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                          <path
                              d="M9.42857 14H24.5714C24.8071 14 25 14.1687 25 14.375V22.5C25 23.3281 24.2321 24 23.2857 24H10.7143C9.76786 24 9 23.3281 9 22.5V14.375C9 14.1687 9.19286 14 9.42857 14ZM25 12.625V11.5C25 10.6719 24.2321 10 23.2857 10H21.5714V8.375C21.5714 8.16875 21.3786 8 21.1429 8H19.7143C19.4786 8 19.2857 8.16875 19.2857 8.375V10H14.7143V8.375C14.7143 8.16875 14.5214 8 14.2857 8H12.8571C12.6214 8 12.4286 8.16875 12.4286 8.375V10H10.7143C9.76786 10 9 10.6719 9 11.5V12.625C9 12.8313 9.19286 13 9.42857 13H24.5714C24.8071 13 25 12.8313 25 12.625Z"
                              fill="#204E77"
                          />
                        </svg>
                      </div>
                      <div class="text">Майбутня акція</div>
                    </div>

                    <div class="more-btn">
                      <p>Детальніше</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <subscribe-block-second></subscribe-block-second>

        <section-links v-if="drugData" :content="drugData"></section-links>
      </div>

      <div class="more-article-wrapper more-article-wrapper-desktop">
        <div @click="backBtn()" class="back-btn">
          <p>До каталогу</p>
          <span>
            <svg
                width="31"
                height="15"
                viewBox="0 0 31 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
              <path
                  d="M29.4727 7.30823L5.84907 7.30823"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
              />
              <path
                  d="M8.61914 13.0408L3.07295 7.27037L8.61914 1.49997"
                  stroke="white"
                  stroke-width="3"
                  stroke-linecap="round"
              />
            </svg>
          </span>
        </div>

        <div class="more-article" v-if="relatedPage !== null">
          <h2 class="h2-title-more">Вас може зацікавити:</h2>

          <div class="articles-wrapper">
            <div
                v-for="(key, value) in relatedPage"
                class="article-item one-section"
                @click="goToArticle(key.slug)"
                v-bind:class="[
                { color1: key.marker === 'past' },
                { color2: key.marker === 'active' },
                { color3: key.marker === 'lastWeek' },
                { color4: key.marker === 'future' }
              ]"
                :key="value"
            >
              <div class="img-wrapper">
                <img
                    class="article-img mob"
                    :src="
                    `${$root.globalUrl}/uploads/title/${key.titlePictureMobile}`
                  "
                    alt
                />

                <img
                    class="article-img desk"
                    :src="`${$root.globalUrl}/uploads/title/${key.titlePicture}`"
                    alt
                />
              </div>

              <div class="description">
                <div class="text-wrapper">
                  <p class="h2-title" v-html="key.shortTitle"></p>
                  <p class="h3-title" v-html="key.shortDescription"></p>
                </div>

                <div class="bottom-wrapper bottom-wrapper1">
                  <div class="marker">
                    <div class="icon">
                      <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                        <path
                            d="M14.6667 24.5143H18.3333C18.3333 25.0144 18.1402 25.4941 17.7964 25.8477C17.4525 26.2014 16.9862 26.4 16.5 26.4C16.0138 26.4 15.5475 26.2014 15.2036 25.8477C14.8598 25.4941 14.6667 25.0144 14.6667 24.5143ZM24.75 22.6286V23.5715H8.25V22.6286L10.0833 20.7429V15.0858C10.0833 12.1629 11.9442 9.58889 14.6667 8.75918V8.48575C14.6667 7.98563 14.8598 7.50599 15.2036 7.15235C15.5475 6.79871 16.0138 6.60004 16.5 6.60004C16.9862 6.60004 17.4525 6.79871 17.7964 7.15235C18.1402 7.50599 18.3333 7.98563 18.3333 8.48575V8.75918C21.0558 9.58889 22.9167 12.1629 22.9167 15.0858V20.7429L24.75 22.6286ZM19.25 13.2H13.75V15.0858H16.72L13.75 17.6315V19.8H19.25V17.9143H16.28L19.25 15.3686V13.2Z"
                            fill="#9E9E9E"
                        />
                      </svg>
                    </div>
                    <div class="text">Минула акція</div>
                  </div>

                  <div class="more-btn">
                    <p>Детальніше</p>
                  </div>
                </div>

                <div class="bottom-wrapper bottom-wrapper2">
                  <div class="marker">
                    <div class="icon">
                      <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                        <path
                            d="M16.5 8C11.8044 8 8 11.8044 8 16.5C8 21.1956 11.8044 25 16.5 25C21.1956 25 25 21.1956 25 16.5C25 11.8044 21.1956 8 16.5 8ZM20.4655 17.3226L14.4333 20.7843C13.8917 21.0859 13.2097 20.6986 13.2097 20.0645V12.9355C13.2097 12.3048 13.8883 11.9141 14.4333 12.2157L20.4655 15.8831C21.0276 16.1984 21.0276 17.0107 20.4655 17.3226Z"
                            fill="#00A03B"
                        />
                      </svg>
                    </div>
                    <div class="text">Активна акція</div>
                  </div>

                  <div class="more-btn">
                    <p>Детальніше</p>
                  </div>
                </div>

                <div class="bottom-wrapper bottom-wrapper3">
                  <div class="marker">
                    <div class="icon">
                      <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                        <path
                            d="M26 22.904C26 23.3252 25.8518 23.6465 25.5553 23.8679C25.2588 24.0893 24.8949 24.2 24.4636 24.2H9.53639C9.10512 24.2 8.74124 24.0866 8.44474 23.8598C8.14825 23.633 8 23.3144 8 22.904C8 22.58 8.09704 22.2506 8.29111 21.9158L15.7466 8.891C16.1024 8.297 16.5229 8 17.0081 8C17.4933 8 17.903 8.297 18.2372 8.891L25.7089 21.932C25.903 22.2776 26 22.6016 26 22.904ZM18.124 14.6258V12.2606H15.876V14.6258C15.876 14.777 15.8868 14.9201 15.9084 15.0551C15.9299 15.1901 15.9596 15.3413 15.9973 15.5087C16.035 15.6761 16.0647 15.8192 16.0863 15.938L16.5067 18.5624H17.4609L17.8976 15.938C17.9191 15.83 17.9515 15.6896 17.9946 15.5168C18.0377 15.344 18.0701 15.1901 18.0916 15.0551C18.1132 14.9201 18.124 14.777 18.124 14.6258ZM18.124 20.8952C18.124 20.582 18.0135 20.3174 17.7925 20.1014C17.5714 19.8854 17.3046 19.7774 16.9919 19.7774C16.69 19.7774 16.4286 19.8854 16.2075 20.1014C15.9865 20.3174 15.876 20.582 15.876 20.8952C15.876 21.2084 15.9865 21.4757 16.2075 21.6971C16.4286 21.9185 16.69 22.0292 16.9919 22.0292C17.3046 22.0292 17.5714 21.9185 17.7925 21.6971C18.0135 21.4757 18.124 21.2084 18.124 20.8952Z"
                            fill="#FB3449"
                        />
                      </svg>
                    </div>
                    <div class="text">
                      Скоро закінчиться
                    </div>
                  </div>

                  <div class="more-btn">
                    <p>Детальніше</p>
                  </div>
                </div>

                <div class="bottom-wrapper bottom-wrapper4">
                  <div class="marker">
                    <div class="icon">
                      <svg
                          width="33"
                          height="33"
                          viewBox="0 0 33 33"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="16.5" cy="16.5" r="16.5" fill="white"/>
                        <path
                            d="M9.42857 14H24.5714C24.8071 14 25 14.1687 25 14.375V22.5C25 23.3281 24.2321 24 23.2857 24H10.7143C9.76786 24 9 23.3281 9 22.5V14.375C9 14.1687 9.19286 14 9.42857 14ZM25 12.625V11.5C25 10.6719 24.2321 10 23.2857 10H21.5714V8.375C21.5714 8.16875 21.3786 8 21.1429 8H19.7143C19.4786 8 19.2857 8.16875 19.2857 8.375V10H14.7143V8.375C14.7143 8.16875 14.5214 8 14.2857 8H12.8571C12.6214 8 12.4286 8.16875 12.4286 8.375V10H10.7143C9.76786 10 9 10.6719 9 11.5V12.625C9 12.8313 9.19286 13 9.42857 13H24.5714C24.8071 13 25 12.8313 25 12.625Z"
                            fill="#204E77"
                        />
                      </svg>
                    </div>
                    <div class="text">Майбутня акція</div>
                  </div>

                  <div class="more-btn">
                    <p>Детальніше</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer-block></footer-block>

    <preloader v-if="preload"></preloader>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "article-page",
  data: () => ({
    color: ["color1", "color2", "color3"],
    preload: false,
    category: [
      {
        name: "Педіатр",
        params: "pediatrician",
        id: "1"
      },
      {
        name: "Терапевт",
        params: "therapist",
        id: "2"
      },
      {
        name: "Отоларинголог",
        params: "otolaryngologist",
        id: "3"
      },
      {
        name: "Невропатолог",
        params: "neurologist",
        id: "4"
      },
      {
        name: "Пульмонолог",
        params: "pulmonologist",
        id: "5"
      },
      {
        name: "Фармацевт",
        params: "pharmacist",
        id: "6"
      },
      {
        name: "Дистриб’ютор",
        params: "distributor",
        id: "7"
      },
      {
        name: "Психіатр",
        params: "psychiatrist",
        id: "8"
      },
      {
        name: "Кардіолог",
        params: "cardiologist",
        id: "9"
      }
    ],
    forColor: {
      currentTime: "",
      oneWeek: null
    },
    forAxios: "",
    name: "",
    data: {},
    content: [],
    defaultData: [],
    types: [],
    drugData: {},
    relatedPage: null,
    remains: [],
    remainsForTable: []
  }),

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.category.forEach(el => {
        if (el.params === this.$route.params.category) {
          this.name = el.name;
          this.forNav = el.params;
          this.forAxios = el.id;
        }
      });
      this.getArticle();
    }
  },

  methods: {
    getRemains() {
      axios
          .get(this.$root.globalUrl + `/api/v1/rests/?count=0`)
          .then(res => {
            const data = res.data.data.map(item => (item.city.indexOf('Dnipro') != -1 ? {...item, city: 'Dnipro'} : item));
            let result = [];

            data.forEach(value => {
              var existing = result.filter((v, i) => {
                return (v.city === value.city) && (v.drugName === value.drugName);
              });
              if (existing.length) {
                var existingIndex = result.indexOf(existing[0]);
                result[existingIndex].quantity += value.quantity;
              } else {
                if((value.quantity)){
                  value.quantity = value.quantity
                  result.push(value); 
                }
              }
            });
            this.remains = result;
            this.getArticle();

          })
    },
    getRandomColor() {
      return this.color[Math.floor(Math.random() * this.color.length)];
    },
    goToArticle(id) {
      this.preload = true;
      this.$router.push({
        name: "article-page",
        params: {id: id, category: this.forNav}
      });
    },
    getArticle() {
      axios
          .get(
              this.$root.globalUrl +
              "/api/v1/pages/" +
              this.$route.params.id +
              "?spec=" +
              this.forAxios
          )
          .then(res => {
            this.data = res.data;

            this.drugData = res.data.drug;

            this.content = JSON.parse(res.data.content);

            var drugRemainsCount = null;
            if (this.data.relatedDrugSku) {
              this.remains.forEach(drugRemain => {
                JSON.parse(this.data.relatedDrugSku).forEach(drugSku => {
                  if (drugRemain.drugName === drugSku) {
                    drugRemainsCount += drugRemain.quantity;
                  }
                });
              });
              this.data.drugRemainsCount = drugRemainsCount;
            } else {
              this.data.drugRemainsCount = null;
            }

            if (this.data.relatedDrugSku !== null) {
              JSON.parse(this.data.relatedDrugSku).forEach(drugSKU => {
                var remainsTable = [];
                this.remains.forEach(drug => {
                  if (drugSKU === drug.drugName) {
                    remainsTable.push(drug);
                  }
                });
                console.log(remainsTable)
                this.remainsForTable.push(remainsTable);
              });
            }

            if (this.data.relatedPages.length > 0) {
              this.relatedPage = this.data.relatedPages;

              this.forColor.currentTime = new Date().getTime();

              this.relatedPage.forEach(el => {
                var drugRemainsCount = null;
                if (el.relatedDrugSku) {
                  this.remains.forEach(drugRemain => {
                    if (drugRemain.drugName === el.relatedDrugSku) {
                      drugRemainsCount += drugRemain.quantity;
                    }
                  });
                  el.drugRemainsCount = drugRemainsCount;
                } else {
                  el.drugRemainsCount = null;
                }

                if (
                    this.forColor.currentTime > new Date(el.promotionEnd).getTime()
                ) {
                  el["marker"] = "past";
                } else if (
                    this.forColor.currentTime >
                    new Date(el.promotionStart).getTime() &&
                    this.forColor.currentTime + this.forColor.oneWeek <
                    new Date(el.promotionEnd).getTime()
                ) {
                  el["marker"] = "active";
                  if (el.promotionQty !== null && el.drugRemainsCount) {
                    if (Math.floor((el.drugRemainsCount * 100) / el.promotionQty) >= 30) {
                      el["marker"] = "active";
                    } else if (Math.floor((el.drugRemainsCount * 100) / el.promotionQty) < 30 &&
                        Math.floor((el.drugRemainsCount * 100) / el.promotionQty) >= 20) {
                      el["marker"] = "lastWeek";
                    } else if (Math.floor((el.drugRemainsCount * 100) / el.promotionQty) <= 19) {
                      el["marker"] = "past";
                    }
                  }
                } else if (
                    this.forColor.currentTime >
                    new Date(el.promotionStart).getTime() &&
                    this.forColor.currentTime + this.forColor.oneWeek >
                    new Date(el.promotionEnd).getTime() &&
                    this.forColor.currentTime < new Date(el.promotionEnd).getTime()
                ) {
                  el["marker"] = "lastWeek";
                  if (el.promotionQty !== null && el.drugRemainsCount) {
                    if (Math.floor((el.drugRemainsCount * 100) / el.promotionQty) >= 30) {
                      el["marker"] = "active";
                    } else if (Math.floor((el.drugRemainsCount * 100) / el.promotionQty) < 30 &&
                        Math.floor((el.drugRemainsCount * 100) / el.promotionQty) >= 20) {
                      el["marker"] = "lastWeek";
                    } else if (Math.floor((el.drugRemainsCount * 100) / el.promotionQty) <= 19) {
                      el["marker"] = "past";
                    }
                  }
                } else if (
                    this.forColor.currentTime <
                    new Date(el.promotionStart).getTime()
                ) {
                  el["marker"] = "future";
                }
              });
            } else {
              this.relatedPage = null;
            }

            this.content.forEach(el => {
              this.types.push(el.type);
            });


            document.title = this.data.description.replace(/<\/?[^>]+>/g, "");

            document.querySelector('meta[property="og:image"]').content =
                this.$root.globalUrl + "/uploads/title/" + this.data.titlePicture;

            this.preload = false;
          })
          .catch(error => {
            console.log(error);
          });
    },
    backBtn() {
      this.$router.push({
        name: "page",
        params: {pageNum: "1", category: this.$route.params.category}
      });
    }
  },
  beforeMount() {
    this.preload = true;
    this.category.forEach(el => {
      if (el.params === this.$route.params.category) {
        this.name = el.name;
        this.forNav = el.params;
        this.forAxios = el.id;
      }
    });
    this.forColor.oneWeek = 14 * 24 * 60 * 60 * 1000;
    this.getRemains();

    axios
      .get(
          this.$root.globalUrl +
          "/api/v1/default_data/"
      )
      .then(res => {
        this.defaultData = res.data;
      });
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1128px;

.article-page {
  width: 100%;

  .promo-main {
    color: #545454;
    font-family: $fontR;
    font-size: 12px;
  }

  .content {
    position: relative;
    z-index: 2;
    max-width: $maxW;
    width: 100%;
    margin: 105px auto 40px auto;
    padding: 0 10px;

    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    @media screen and (max-width: 425px) {
      padding: 0 15px;
    }

    .article-wrapper {
      max-width: 830px;
      min-width: 726px;
      width: 100%;
      margin-right: 10px;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1024px) {
        max-width: 1024px;
        min-width: 280px;
        margin-right: 0;
      }

      @media screen and (max-width: 670px) {
        margin-right: 0;
      }

      .h2-title {
        font-family: $fontR;
        color: #545454;
        font-size: 32px;
        margin: 15px 0;
        @media screen and (max-width: 670px) {
          margin: 15px 0;
          font-size: 18px;
        }

        span {
          text-decoration: underline;
          color: #00a03b;
          font-family: $fontDemi;
        }
      }

      .h1-title {
        color: #00a03b;
        font-family: $fontDemi;
        font-size: 40px;
        margin: 15px 0;
        @media screen and (max-width: 670px) {
          margin: 15px 0;
          font-size: 18px;
        }
      }

      .h3-title {
        margin: 15px 0;
        color: #00a03b;
        font-family: $fontR;
        font-size: 30px;
        font-weight: normal;

        @media screen and (max-width: 670px) {
          margin: 15px 0;
          font-size: 18px;
        }

        b,
        strong {
          font-family: $fontDemi;
          font-weight: normal;
        }
      }
    }

    .more-article-wrapper {
      min-width: 258px;
      max-width: 258px;
      width: 100%;
      margin-left: 10px;

      &.more-article-wrapper-desktop {
        @media screen and (max-width: 1024px) {
          display: none;
        }
      }

      &.more-article-wrapper-mobile {
        display: none;
        @media screen and (max-width: 1024px) {
          display: block;
        }
      }

      @media screen and (max-width: 1024px) {
        max-width: 1024px;
        margin-left: 0;
        padding: 0 10px;
      }

      @media screen and (max-width: 425px) {
        padding: 0;
      }

      .back-btn {
        cursor: pointer;
        background: #aa198b;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px 0 35px;
        height: 63px;
        border-radius: 50px;
        @media screen and (max-width: 1024px) {
          display: none;
        }

        &:hover {
          span {
            transform: translateX(-20px);
          }
        }

        p {
          color: white;
          font-size: 22px;
          font-family: $fontR;
        }

        span {
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
        }
      }

      .h2-title-more {
        margin: 60px 0 30px 0;
        color: #00a03b;
        font-family: $fontDemi;
        font-size: 20px;
        font-weight: normal;
        @media screen and (max-width: 1024px) {
          margin: 0 0 30px 0;
        }
      }

      .articles-wrapper {
        display: flex;
        flex-flow: column;
        margin: 15px 0;
        @media screen and (max-width: 1024px) {
          flex-flow: row wrap;
          justify-content: center;
        }

        .article-item {
          cursor: pointer;
          position: relative;
          display: flex;
          flex-direction: column;
          margin: 0 7px 14px 7px;

          @media screen and (max-width: 572px) {
            max-height: 1000px;
            height: auto;
            margin: 0 7px 15px 7px;
          }

          .img-wrapper {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
              content: "";
              left: 0;
              top: 0;
              right: 0;
              bottom: 0;
              width: 100%;
              height: 100%;
              z-index: 1;
              opacity: 0.3;
              background: url("../assets/img/item-pattern.png") center;
              background-size: 100%;

              position: absolute;
            }

            img {
              position: relative;
              z-index: 2;
            }
          }

          &.color1 {
            .img-wrapper {
              background: linear-gradient(0deg, #dcdada, #dcdada),
              linear-gradient(
                      180deg,
                      #eae8e8 42.03%,
                      #cbcbcb 51.22%,
                      #e3e0e0 84.06%
              );
            }

            .description {
              background: #aeaeae;
            }

            .more-btn {
              p {
                color: #545454;
              }
            }

            .bottom-wrapper1 {
              display: flex !important;
            }

            &:hover {
              .more-btn {
                border: 1px solid white !important;

                background: #aeaeae !important;

                p {
                  color: white !important;
                }
              }
            }
          }

          &.color2 {
            .img-wrapper {
              background: linear-gradient(0deg, #43b441, #43b441),
              linear-gradient(
                      180deg,
                      #84bf82 36.34%,
                      #6fb36d 57.79%,
                      #8ecc8c 84.06%
              );
            }

            .description {
              background: #00a03b;
            }

            .more-btn {
              p {
                color: #00a03b;
              }
            }

            .bottom-wrapper2 {
              display: flex !important;
            }

            &:hover {
              .more-btn {
                border: 1px solid white !important;
                background: #00a03b !important;

                p {
                  color: white !important;
                }
              }
            }
          }

          &.color3 {
            .img-wrapper {
              background: linear-gradient(0deg, #ff5163, #ff5163),
              linear-gradient(
                      180deg,
                      #ec8787 39.4%,
                      #e36969 56.04%,
                      #ff9999 84.06%
              ),
              linear-gradient(233.08deg, #d1d0d0 14.63%, #f8f8f8 91.06%),
              linear-gradient(
                      180deg,
                      #eae8e8 41.15%,
                      #cbcbcb 52.97%,
                      #e3e0e0 84.06%
              );
            }

            .description {
              background: #fb3449;
            }

            .bottom-wrapper3 {
              display: flex !important;
            }

            .more-btn {
              p {
                color: #fb3449;
              }
            }

            &:hover {
              .more-btn {
                border: 1px solid white !important;
                background: #fb3449 !important;

                p {
                  color: white !important;
                }
              }
            }
          }

          &.color4 {
            .img-wrapper {
              background: linear-gradient(0deg, #2f608c, #2f608c),
              linear-gradient(
                      180deg,
                      #eae8e8 42.03%,
                      #cbcbcb 51.22%,
                      #e3e0e0 84.06%
              );
            }

            .description {
              background: #204e77;
            }

            .bottom-wrapper4 {
              display: flex !important;
            }

            .more-btn {
              p {
                color: #545454;
              }
            }

            &:hover {
              .more-btn {
                border: 1px solid white !important;
                background: #204e77 !important;

                p {
                  color: white !important;
                }
              }
            }
          }

          &.one-section {
            max-width: 262px;
            width: 100%;
            position: relative;

            @media screen and (max-width: 572px) {
              max-width: 572px;
            }

            .bottom-wrapper {
              display: none;

              align-self: flex-end;

              justify-content: space-between;
              align-items: center;

              margin: auto 0 0 auto;
              width: 100%;

              .more-btn {
                border: 1px solid rgba(255, 255, 255, 0);
                cursor: pointer;
                user-select: none;
                max-width: 80px;

                background: #f5f5f5;
                border-radius: 50px 0 0 50px;
                transition: all 0.2s ease;

                &:hover {
                  background: #00a03b;

                  p {
                    color: white;
                  }
                }

                * {
                  transition: all 0.2s ease;
                }

                p {
                  padding: 6px 10px;
                  font-family: $fontR;
                  font-size: 9px;
                }
              }

              .marker {
                padding-left: 15px;
                margin-right: 15px;
                display: flex;
                align-items: center;

                .icon {
                  display: flex;
                  align-items: center;
                  margin-right: 10px;
                }

                .text {
                  color: #ffffff;
                  font-size: 15px;
                  font-family: $fontDemi;
                  text-decoration: underline;
                }
              }
            }

            .description {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              flex-direction: column;
              padding-bottom: 10px;

              @media screen and (max-width: 572px) {
                max-height: 1000px;
                height: auto;
              }

              .text-wrapper {
                margin-bottom: 10px;
                width: 100%;
                max-height: 87px;
                overflow: hidden;
                padding: 18px 22px 0 22px;
                @media screen and (max-width: 572px) {
                  max-height: 1000px;
                  height: auto;
                }
              }

              .h2-title {
                max-height: 36px;
                overflow: hidden;
                color: white;
                font-size: 14px;
                font-family: $fontDemi;
              }

              .h3-title {
                max-height: 29px;
                margin-top: 5px;
                font-family: $fontR;
                font-size: 11px;
                color: white;
              }
            }

            .article-img {
              width: 100%;
              height: auto;
              display: block;

              &.desk {
                @media screen and (max-width: 572px) {
                  display: none;
                }
              }

              &.mob {
                display: none;
                @media screen and (max-width: 572px) {
                  display: block;
                }
              }
            }
          }

          &.two-section {
            max-width: 537px;
            width: 100%;
            position: relative;

            display: flex;
            flex-direction: row;

            @media screen and (max-width: 572px) {
              flex-direction: column;
              max-width: 572px;
            }

            .article-img {
              max-width: 284px;
              width: 100%;
              height: auto;
              display: block;

              &.desk {
                @media screen and (max-width: 572px) {
                  display: none;
                }
              }

              &.mob {
                display: none;
                @media screen and (max-width: 572px) {
                  display: block;
                }
              }

              @media screen and (max-width: 572px) {
                max-width: 572px;
              }
            }

            .description {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              width: 100%;
              height: 100%;
              max-width: 284px;
              position: relative;
              padding-bottom: 10px;

              @media screen and (max-width: 572px) {
                max-width: 572px;
              }

              &:before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                width: 100%;
                height: 100%;

                background: url("../assets/img/page/pattern2.png") no-repeat center;
                background-size: cover;
                @media screen and (max-width: 572px) {
                  display: none;
                }
              }

              .text-wrapper {
                position: relative;
                width: 100%;
                max-height: 160px;
                overflow: hidden;
                padding: 50px 50px 0 22px;

                @media screen and (max-width: 572px) {
                  padding: 18px 22px 10px 22px;
                  max-height: 1000px;
                  height: auto;
                }

                .h2-title {
                  color: white;
                  font-size: 14px;
                  font-family: $fontDemi;
                }

                .h3-title {
                  margin-top: 5px;
                  font-family: $fontR;
                  font-size: 11px;
                  color: white;
                }
              }

              .bottom-wrapper {
                display: none;

                align-self: flex-end;

                justify-content: space-between;
                align-items: center;

                margin: auto 0 0 auto;
                width: 100%;

                .marker {
                  padding-left: 15px;
                  margin-right: 15px;
                  display: flex;
                  align-items: center;

                  .icon {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                  }

                  .text {
                    color: #ffffff;
                    font-size: 15px;
                    font-family: $fontDemi;
                    text-decoration: underline;
                  }
                }

                .more-btn {
                  border: 1px solid rgba(255, 255, 255, 0);
                  cursor: pointer;
                  user-select: none;
                  max-width: 80px;

                  background: #f5f5f5;
                  border-radius: 50px 0 0 50px;
                  transition: all 0.2s ease;

                  &:hover {
                    background: #00a03b;

                    p {
                      color: white;
                    }
                  }

                  * {
                    transition: all 0.2s ease;
                  }

                  p {
                    padding: 6px 10px;
                    font-family: $fontR;
                    font-size: 9px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .drug-remains {
    margin-bottom: 30px;

    &__title {
      margin-bottom: 15px;
      font-family: $fontDemi;
      font-size: 18px;
      color: #00a03b;
    }

    .table {
      width: 100%;

      &-wrapper {
        width: 100%;
        margin: 10px 0 0;
        display: flex;
        flex-direction: column;
      }

      &-head {
        position: relative;

        &__row {
          display: flex;
        }

        &__item {
          position: relative;
          padding: 16px 15px 15px;
          background-color: #005a85;
          border-right: 2px solid #fff;
          font-family: $fontB;
          font-size: 13px;
          text-align: center;
          color: #ffffff;

          &:nth-child(1) {
            flex-basis: 20%;
            max-width: 20%;
          }

          &:nth-child(2) {
            flex-basis: 20%;
            max-width: 20%;
          }

          &:nth-child(3) {
            flex-basis: 45%;
            max-width: 45%;
          }

          &:nth-child(4) {
            flex-basis: 15%;
            max-width: 15%;
            border-right: none;
          }
        }
      }

      &__row {
        display: flex;
        flex-direction: row;

        &:nth-child(2n) {
          .table {
            &__col {
              background: #ececec;
            }
          }
        }

        &:hover {
          .table {
            &__col {
              background: rgba($color: #00a03b, $alpha: 0.2);
            }
          }
        }
      }

      &__col {
        color: #545454;
        font-family: $fontR;
        font-size: 14px;
        text-align: center;
        padding: 15px;
        background: rgba($color: #f2f2f2, $alpha: 0.5);
        border-right: 2px solid #fff;

        strong {
          display: none;
        }

        &:nth-child(1) {
          flex-basis: 20%;
          max-width: 20%;
        }

        &:nth-child(2) {
          flex-basis: 20%;
          max-width: 20%;
        }

        &:nth-child(3) {
          flex-basis: 45%;
          max-width: 45%;
        }

        &:nth-child(4) {
          flex-basis: 15%;
          max-width: 15%;
          border-right: none;
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .article-page {
    .drug-remains {
      .table {
        &-head {
          display: none;

          &__row {
            flex-direction: column;
          }

          &__item {
            &:nth-child(1n) {
              flex-basis: 100%;
              max-width: 100%;
              border-right: none;
              border-bottom: 2px solid #fff;
            }
          }
        }

        &__row {
          flex-direction: column;
          border-bottom: 2px solid #fff;
        }

        &__col {
          strong {
            display: inline-block;
          }

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            flex-basis: 100%;
            max-width: 100%;
            padding: 5px 15px;
            border-right: none;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
