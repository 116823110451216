<template>
  <div class="remains">
    <headed-block></headed-block>
    <content-page-parallax></content-page-parallax>

    <div class="content">
      <div class="top-line2">
        <p class="text">
          Залишки
          <span> /про наявність товару запитуйте у ваших дистриб‘юторів/ </span>
        </p>
      </div>

      <div class="table-head">
        <div @click="backBtn()" class="back-btn">
          <p>До каталогу</p>
          <span>
            <svg
              width="31"
              height="15"
              viewBox="0 0 31 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M29.4727 7.30823L5.84907 7.30823"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
              <path
                d="M8.61914 13.0408L3.07295 7.27037L8.61914 1.49997"
                stroke="white"
                stroke-width="3"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </div>

        <div class="search-wrapper">
          <input
            type="text"
            v-model="searchValue"
            @input="getAllDrugs()"
            autocomplete="off"
            spellcheck="false"
            placeholder="Пошук..."
          />
        </div>
      </div>

      <!-- Begin drugs table -->
      <div class="table-wrapper">
        <div class="current-date">
          {{ getCurrentDay() }}
        </div>
        <div class="table-filter">
          <div class="table-filter__row">
            <div
              class="table-filter__select"
              :class="{ active: dropdowns.isOpenedCitiesDropdown }"
              @click.self="toggleDropdownOptions('isOpenedCitiesDropdown')"
            >
              Склад (місто)
            </div>
            <div
              class="table-filter__select"
              :class="{ active: dropdowns.isOpenedBrandsDropdown }"
              @click.self="toggleDropdownOptions('isOpenedBrandsDropdown')"
            >
              Бренд
            </div>
            <div
              class="table-filter__select"
              :class="{ active: dropdowns.isOpenedDrugsDropdown }"
              @click.self="toggleDropdownOptions('isOpenedDrugsDropdown')"
            >
              Препарат
            </div>
            <div class="table-filter__select text-field">
              <div class="table-filter__select-field">
                <input
                  type="text"
                  v-model="checkedCount"
                  placeholder="Кількість"
                />
              </div>
            </div>

            <!-- cities select options  -->
            <div
              class="table-filter__options table-filter__options--cities"
              v-if="dropdowns.isOpenedCitiesDropdown"
            >
              <div class="table-filter__options-inner">
                <div class="table-filter__options-checkbox">
                  <input
                    id="city-checkbox-all"
                    type="checkbox"
                    v-model="isCheckedAllCities"
                  />
                  <label for="city-checkbox-all">Всі</label>
                </div>
                <div
                  class="table-filter__options-checkbox"
                  v-for="(city, key) in unicalCities"
                  :key="key"
                >
                  <input
                    :id="`city-checkbox-${key}`"
                    :checked="city.isSelected"
                    v-model="checkedCities"
                    :value="city.name"
                    name="city"
                    type="checkbox"
                  />
                  <label :for="`city-checkbox-${key}`">{{ city.name }}</label>
                </div>
              </div>
            </div>

            <div
              class="table-filter__options table-filter__options--brands"
              v-if="dropdowns.isOpenedBrandsDropdown"
            >
              <div class="table-filter__options-inner">
                <div class="table-filter__options-checkbox">
                  <input
                    id="brand-checkbox-all"
                    type="checkbox"
                    v-model="isCheckedAllBrands"
                  />
                  <label for="brand-checkbox-all">Всі</label>
                </div>
                <div
                  class="table-filter__options-checkbox"
                  v-for="(brand, key) in unicalBrands"
                  :key="key"
                >
                  <input
                    :id="`brand-checkbox-${key}`"
                    :checked="brand.isSelected"
                    v-model="checkedBrands"
                    :value="brand.brand"
                    name="brand"
                    type="checkbox"
                  />
                  <label :for="`brand-checkbox-${key}`">{{
                    brand.brand
                  }}</label>
                </div>
              </div>
            </div>

            <div
              class="table-filter__options table-filter__options--drugs"
              v-if="dropdowns.isOpenedDrugsDropdown"
            >
              <div class="table-filter__options-inner">
                <div
                  class="table-filter__options-checkbox  table-filter__options-checkbox--drugname"
                >
                  <input
                    id="drug-checkbox-all"
                    type="checkbox"
                    v-model="isCheckedAllDrugs"
                  />
                  <label for="drug-checkbox-all">Всі</label>
                </div>
                <div
                  class="table-filter__options-checkbox table-filter__options-checkbox--drugname"
                  v-for="(drug, key) in unicalDrugs"
                  :key="key"
                >
                  <input
                    :id="`drug-checkbox-${key}`"
                    :checked="drug.isSelected"
                    v-model="checkedDrugs"
                    :value="drug.drugName"
                    name="drug"
                    type="checkbox"
                  />
                  <label :for="`drug-checkbox-${key}`">{{
                    drug.drugName
                  }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table" v-if="getFilteredDrugs.length !== 0">
          <template
            v-for="(drug, key) in getFilteredDrugs.slice(0, drugsToShow)"
          >
            <div class="table__row" :key="key">
              <div class="table__col">
                <strong>Склад (місто):</strong> {{ drug.city }}
              </div>
              <div class="table__col">
                <strong>Бренд:</strong> {{ drug.brand }}
              </div>
              <div class="table__col">
                <strong>Препарат:</strong> {{ drug.drugName }}
              </div>
              <div class="table__col">
                <strong>Кількість:</strong> {{ drug.quantity }}
              </div>
            </div>
          </template>
        </div>

        <!-- Begin total drugs -->
        <div v-else class="nothing-found">
          За вашим запитом нічого не знайдено.
        </div>
        <!-- Begin total drugs -->
        <div class="total-drugs" v-if="getFilteredDrugs.length !== 0">
          <div class="total-drugs__progress">
            <div
              class="total-drugs__progress-bar"
              :style="{
                width: getProgressWidth
              }"
            ></div>
          </div>
          {{ getShowDrugsCount }}
          /
          {{ getTotalDrugs }}
        </div>

        <!-- Begin load more button -->
        <div
          class="load-more"
          v-if="isVisibleLoadMore && getFilteredDrugs.length !== 0"
        >
          <button @click="loadMore()">Завантажити ще...</button>
        </div>
      </div>
      <subscribe-block></subscribe-block>
    </div>

    <footer-block></footer-block>

    <preloader v-if="preload"></preloader>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "remains",
  data: () => ({
    preload: false,
    name: "Педіатр",

    // search form option
    searchValue: "",
    unicalCities: [],
    unicalBrands: [],
    unicalDrugs: [],

    isCheckedAllCities: true,
    checkedCities: [],

    isCheckedAllBrands: true,
    checkedBrands: [],

    isCheckedAllDrugs: true,
    checkedDrugs: [],

    checkedCount: "",

    dropdowns: {
      isOpenedCitiesDropdown: false,
      isOpenedBrandsDropdown: false,
      isOpenedDrugsDropdown: false,
      isOpenedCountsDropdown: false
    },

    // drugs option
    allDrugs: [],
    drugsToShow: 50,
    monthNames: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    isVisibleLoadMore: true
  }),
  computed: {
    getFilteredDrugs() {
      return this.allDrugs.filter(drug => {
        if (
          (this.checkedCities.length === 0 ||
            this.checkedCities.includes(drug.city)) &&
          (this.checkedBrands.length === 0 ||
            this.checkedBrands.includes(drug.brand)) &&
          (this.checkedDrugs.length === 0 ||
            this.checkedDrugs.includes(drug.drugName)) &&
          (this.checkedCount === "" ||
            Number(this.checkedCount) >= drug.quantity)
        ) {
          return drug;
        }
      });
    },
    getProgressWidth() {
      if (
        (this.checkedCities.length !== 0 ||
          this.checkedBrands.length !== 0 ||
          this.checkedDrugs.length !== 0 ||
          this.checkedCount.trim()) &&
        this.getFilteredDrugs.length <= 50
      ) {
        let progressWidth = "100%";
        return progressWidth;
      } else {
        let progressWidth =
          (this.drugsToShow * 100) / this.getFilteredDrugs.length + "%";
        return progressWidth;
      }
    },
    getShowDrugsCount() {
      if (
        this.checkedCities.length !== 0 ||
        this.checkedBrands.length !== 0 ||
        this.checkedDrugs.length !== 0 ||
        this.checkedCount.trim()
      ) {
        return this.getFilteredDrugs.slice(0, this.drugsToShow).length;
      } else {
        return this.drugsToShow;
      }
    },
    getTotalDrugs() {
      if (this.getFilteredDrugs.length <= 50) {
        return this.getFilteredDrugs.length;
      } else {
        return this.getFilteredDrugs.length;
      }
    }
  },
  watch: {
    getFilteredDrugs(drugsArray) {
      if (drugsArray.length >= 50) {
        this.drugsToShow = 50;
      } else {
        this.drugsToShow = drugsArray.length;
      }
    },
    checkedCities(checkedCities) {
      if (checkedCities.length !== 0) {
        this.isCheckedAllCities = false;
      } else {
        this.isCheckedAllCities = true;
      }
    },
    checkedBrands(checkedBrands) {
      if (checkedBrands.length !== 0) {
        this.isCheckedAllBrands = false;
      } else {
        this.isCheckedAllBrands = true;
      }
    },
    checkedDrugs(checkedDrugs) {
      if (checkedDrugs.length !== 0) {
        this.isCheckedAllDrugs = false;
      } else {
        this.isCheckedAllDrugs = true;
      }
    },
    getShowDrugsCount(showDrugsCount) {
      if (
        this.getFilteredDrugs.length < 50 ||
        showDrugsCount === this.getTotalDrugs
      ) {
        this.isVisibleLoadMore = false;
      } else {
        this.isVisibleLoadMore = true;
      }
    }
  },
  methods: {
    // get drugs
    async getAllDrugs() {
      await axios
        .get(
          this.$root.globalUrl +
            `/api/v1/rests/?count=0&search=${this.searchValue.trim()}`
        )
        .then(res => {
            const data = res.data.data.map(item => (item.city.indexOf('Dnipro') != -1 ? {...item, city: 'Dnipro'} : item));
            let result = [];

            data.forEach(value => {
              var existing = result.filter((v, i) => {
                return (v.city === value.city) && (v.drugName === value.drugName);
              });
              if (existing.length) {
                var existingIndex = result.indexOf(existing[0]);
                result[existingIndex].quantity += value.quantity;
              } else {
                if((value.quantity)){
                  value.quantity = value.quantity
                  result.push(value); 
                }
              }
            });
            this.allDrugs = result;
            result.forEach(drug => {
            this.getUnicalDrugFields(drug);
          });
        })
        .catch(() => {});
    },
    // filter logic methods
    getUnicalDrugFields(drug) {
      if (
        !this.unicalCities.some(unicalCity => unicalCity.name === drug.city)
      ) {
        this.unicalCities.push({
          name: drug.city,
          isSelected: true
        });
      }
      if (
        !this.unicalBrands.some(unicalBrand => unicalBrand.brand === drug.brand)
      ) {
        this.unicalBrands.push({
          brand: drug.brand,
          isSelected: true
        });
      }
      if (
        !this.unicalDrugs.some(
          unicalDrug => unicalDrug.drugName === drug.drugName
        )
      ) {
        this.unicalDrugs.push({
          drugName: drug.drugName,
          isSelected: true
        });
      }
    },
    // dropdown methods
    toggleDropdownOptions(dropdown) {
      for (let dropdownItem of Object.keys(this.dropdowns)) {
        if (dropdown === dropdownItem) {
          this.dropdowns[dropdown] = !this.dropdowns[dropdown];
        } else {
          this.dropdowns[dropdownItem] = false;
        }
      }
    },
    // get current day
    getCurrentDay() {
      var month = this.monthNames[
        new Date(new Date().setDate(new Date().getDate() - 1)).getMonth()
      ];
      var day = new Date(
        new Date().setDate(new Date().getDate() - 1)
      ).getDate();
      return month + " " + day;
    },
    // button load more drugs
    loadMore() {
      if (this.getFilteredDrugs.length - this.drugsToShow >= 50) {
        this.drugsToShow += 50;
      } else {
        this.drugsToShow += this.getFilteredDrugs.length - this.drugsToShow;
      }
    },
    backBtn() {
      this.$router.push({
        name: "page",
        params: { pageNum: "1", category: this.$route.params.category }
      });
    }
  },

  created() {
    this.getAllDrugs();
  },
  mounted() {
    document.querySelector('meta[property="og:image"]').content =
      this.$root.globalUrl + "/assets/img/teva_logo_for_site.jpg";
    document.title = "Rx-портал Тева Україна";
  }
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
.remains {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input,
    textarea {
      background-image: -webkit-gradient(
        linear,
        0 0,
        0 100%,
        from(rgba(255, 255, 255, 0)),
        to(rgba(255, 255, 255, 0))
      );
      background-image: -webkit-linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      );
      -webkit-border-radius: 0;
      border-radius: 0;
    }
  }
  .content {
    display: flex;
    flex-direction: column;

    position: relative;
    z-index: 2;

    margin: 95px auto 40px auto;
    padding: 0 10px;
    max-width: 1128px;
    width: 100%;

    min-height: calc(100vh - 239px);
    height: 100%;

    .top-line2 {
      margin: 30px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;

      .text {
        color: #00a03b;
        font-family: $fontDemi;
        font-size: 40px;
        span {
          display: inline-block;
          font-size: 21px;
          vertical-align: middle;
          color: #545454;
        }
      }
    }

    .table {
      &-head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .back-btn {
          width: 100%;
          max-width: 255px;
          height: 63px;
          background: #aa198b;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 0 25px 0 45px;
          border-radius: 50px;
          cursor: pointer;

          &:hover {
            span {
              transform: translateX(-20px);
            }
          }

          p {
            color: white;
            font-size: 22px;
            font-family: $fontR;
          }

          span {
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
          }
        }
        .search-wrapper {
          flex-basis: 255px;
          max-width: 255px;
          :focus::-webkit-input-placeholder {
            opacity: 0;
          }
          :focus:-moz-placeholder {
            opacity: 0;
          }
          :focus::-moz-placeholder {
            opacity: 0;
          }
          :focus:-ms-input-placeholder {
            opacity: 0;
          }
          input {
            display: block;
            width: 100%;
            height: 39px;
            padding: 0 27px 0 34px;
            background-color: #e9e9e9;
            background-image: url("~@/assets/img/remains/icon-search.png");
            background-repeat: no-repeat;
            background-position: right center;
            background-origin: content-box;
            border-radius: 71px;
            border: none;
            font-size: 18px;
            user-select: text;
            color: #545454;
            overflow: visible;
            &:focus {
              outline: 0;
            }
          }
        }
      }
      &-filter {
        position: relative;
        &__row {
          display: flex;
        }
        &__select {
          position: relative;
          padding: 16px 15px 15px;
          background-color: #005a85;
          border-right: 2px solid #fff;
          font-family: $fontB;
          font-size: 13px;
          text-align: center;
          color: #ffffff;
          cursor: pointer;

          &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 15px;
            transform: translateY(-50%) rotate(0deg);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0 5px;
            border-color: #ffffff transparent transparent transparent;
          }
          &.active {
            background-color: #e4eaed;
            color: #005a85;
            &:after {
              transform: translateY(-50%) rotate(180deg);
              border-color: #005a85 transparent transparent transparent;
            }
          }
          &:nth-child(1) {
            flex-basis: 20%;
            max-width: 20%;
          }
          &:nth-child(2) {
            flex-basis: 20%;
            max-width: 20%;
          }
          &:nth-child(3) {
            flex-basis: 45%;
            max-width: 45%;
          }
          &:nth-child(4) {
            flex-basis: 15%;
            max-width: 15%;
            border-right: none;
          }
          &.text-field {
            padding: 0;
            ::-webkit-input-placeholder {
              font-size: 13px;
              color: #ffffff;
            }
            :-moz-placeholder {
              font-size: 13px;
              color: #ffffff;
            }
            ::-moz-placeholder {
              font-size: 13px;
              color: #ffffff;
            }
            :-ms-input-placeholder {
              font-size: 13px;
              color: #ffffff;
            }
            :focus::-webkit-input-placeholder {
              opacity: 0;
            }
            :focus:-moz-placeholder {
              opacity: 0;
            }
            :focus::-moz-placeholder {
              opacity: 0;
            }
            :focus:-ms-input-placeholder {
              opacity: 0;
            }

            input {
              display: block;
              width: 100%;
              padding: 14px 15px;
              background-color: #005a85;
              border: 2px solid transparent;
              font-family: $fontB;
              font-size: 13px;
              text-align: center;
              color: #ffffff;
              &:focus {
                outline: none;
                background-color: #fff;
                border-color: #005a85;
                color: #005a85;
              }
            }
            &:after {
              display: none;
            }
          }
        }
        &__options {
          position: absolute;
          top: 50px;
          left: 0;
          right: 0;
          background: #e4eaed;
          padding: 15px 30px;
          box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.09);
          z-index: 10;
          &-inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin: 0 -10px;
          }
          &-checkbox {
            display: flex;
            flex-basis: 15%;
            max-width: 15%;
            padding: 0 10px;
            margin-bottom: 10px;
            &--drugname {
              flex-basis: 50%;
              max-width: 50%;
            }
            input {
              display: none;
              &:checked {
                + label {
                  &:after {
                    opacity: 1;
                    visibility: visible;
                  }
                }
              }
            }
            label {
              position: relative;
              padding-left: 25px;
              font-size: 10px;
              line-height: 22px;
              color: #005a85;
              cursor: pointer;
              &:before {
                content: "";
                position: absolute;
                top: 2px;
                left: 0;
                height: 16px;
                width: 16px;
                border: 1px solid #005a85;
              }
              &:after {
                content: "";
                position: absolute;
                top: 5px;
                left: 6px;
                width: 4px;
                height: 7px;
                border: solid #005a85;
                border-width: 0 2px 2px 0;
                transform: rotate(45deg);
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.5s;
              }
            }
          }
        }
      }
      &-wrapper {
        width: 100%;
        margin: 10px 0 0;
        display: flex;
        flex-direction: column;
        .current-date {
          margin-bottom: 10px;
          font-size: 12px;
          text-transform: uppercase;
          text-align: right;
          color: #005a85;
        }
        .table {
          width: 100%;
          &__row {
            display: flex;
            flex-direction: row;
            &:nth-child(2n) {
              .table {
                &__col {
                  background: #ececec;
                }
              }
            }
            &:hover {
              .table {
                &__col {
                  background: rgba($color: #00a03b, $alpha: 0.2);
                }
              }
            }
          }

          &__col {
            color: #545454;
            font-family: $fontR;
            font-size: 14px;
            text-align: center;
            padding: 15px;
            background: rgba($color: #f2f2f2, $alpha: 0.5);
            border-right: 2px solid #fff;
            strong {
              display: none;
            }
            &:nth-child(1) {
              flex-basis: 20%;
              max-width: 20%;
            }
            &:nth-child(2) {
              flex-basis: 20%;
              max-width: 20%;
            }
            &:nth-child(3) {
              flex-basis: 45%;
              max-width: 45%;
            }
            &:nth-child(4) {
              flex-basis: 15%;
              max-width: 15%;
              border-right: none;
            }
          }
        }
      }
    }

    .nothing-found {
      margin-top: 70px;
      margin-bottom: 30px;
      font-size: 21px;
      text-align: center;
      color: #545454;
    }

    .total-drugs {
      margin-top: 70px;
      margin-bottom: 32px;
      font-weight: 600;
      font-size: 13px;
      text-align: center;
      line-height: 16px;
      color: #00a03b;
      &__progress {
        position: relative;
        width: 100%;
        height: 4px;
        max-width: 356px;
        margin: 0 auto 16px;
        background: #f1f1f1;
        &-bar {
          position: absolute;
          top: 0;
          left: 0;
          height: 4px;
          background: #00a03b;
        }
      }
    }

    .load-more {
      margin-bottom: 68.5px;
      text-align: center;
      button {
        width: 255px;
        height: 63px;
        background: #aa198b;
        border-radius: 150px;
        border: none;
        font-weight: normal;
        font-size: 22.5px;
        line-height: 63px;
        color: #fff;
        cursor: pointer;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

@media screen and(max-width: 991px) {
  .remains {
    .content {
      margin: 60px auto 40px auto;
      .top-line2 {
        flex-direction: column;
        align-items: flex-start;
        .text {
          margin-bottom: 30px;
          font-size: 30px;
          span {
            font-size: 14px;
          }
        }
      }
      .table {
        &-filter {
          &__options {
            &-checkbox {
              flex-basis: 20%;
              max-width: 20%;
              &--drugname {
                flex-basis: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
      .nothing-found {
        margin-top: 30px;
      }
      .load-more {
        margin-bottom: 30px;
        button {
          height: 50px;
          font-size: 20px;
          line-height: 50px;
        }
      }
    }
  }
}

@media screen and(max-width: 767px) {
  .remains {
    .content {
      .top-line2 {
        margin-top: 0;
        .text {
          margin-bottom: 0;
        }
      }
      .table {
        &-head {
          .back-btn,
          .search-wrapper {
            flex-basis: 100%;
            max-width: 100%;
          }
          .back-btn {
            height: 50px;
            margin-bottom: 10px;
            p {
              font-size: 20px;
              line-height: 50px;
            }
          }
        }
        &-wrapper {
          .table {
            &__row {
              flex-direction: column;
              border-bottom: 2px solid #fff;
            }
            &__col {
              strong {
                display: inline-block;
              }
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                flex-basis: 100%;
                max-width: 100%;
                padding: 5px 15px;
                border-right: none;
                text-align: left;
              }
            }
          }
        }
        &-filter {
          &__row {
            flex-direction: column;
          }
          &__select {
            &:nth-child(1n) {
              flex-basis: 100%;
              max-width: 100%;
              border-right: none;
              border-bottom: 2px solid #fff;
            }
          }
          &__options {
            &--cities {
              top: 49px;
            }
            &--brands {
              top: 98px;
            }
            &--drugs {
              top: 147px;
            }
            &-checkbox {
              flex-basis: 25%;
              max-width: 25%;
              &--drugname {
                flex-basis: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width: 580px) {
  .remains {
    .content {
      .table {
        &-filter {
          &__options {
            &-checkbox {
              flex-basis: 50%;
              max-width: 50%;
              &--drugname {
                flex-basis: 100%;
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and(max-width: 425px) {
  .remains {
    .content {
    }
  }
}
</style>
