<template>
  <div class="subscribe-block-second">
    <img
      class="left-img desktop"
      :src="require('../assets/img/sub-img.jpg')"
      alt=""
    />
    <img
      class="left-img mob"
      :src="require('../assets/img/sub-img-mob.jpg')"
      alt=""
    />

    <div class="left-side">
      <p class="reg-text">
        Підпишіться, щоб отримувати новини від <b>Тева Україна</b>
      </p>

      <a
        href="https://consent.teva.ua/"
        target="_blank"
        style="text-decoration: none"
      >
        <div class="sub-btn">
          <p>Підписатися</p>
          <span>
            <svg
              width="20"
              height="10"
              viewBox="0 0 20 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 4.82178L16.7491 4.82178"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M14.9033 1L18.6008 4.84693L14.9033 8.69387"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </span>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "subscribe-block-second"
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1128px;
.subscribe-block-second {
  overflow: hidden;
  margin: 15px 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #005a85;
  min-height: 232px;
  @media screen and (max-width: 670px) {
    flex-direction: column;
    max-width: 500px;
    margin: 15px auto;
  }

  .left-img {
    display: block;
    max-width: 264px;
    width: 100%;
    height: auto;

    &.desktop {
      @media screen and (max-width: 670px) {
        display: none;
      }
    }

    &.mob {
      display: none;
      @media screen and (max-width: 670px) {
        display: block;
      }
    }

    @media screen and (max-width: 670px) {
      max-width: 500px;
    }
  }

  .reg-text {
    max-width: 328px;
    width: 100%;
    color: white;
    font-size: 17px;
    font-family: $fontL;
    @media screen and (max-width: 670px) {
      font-size: 14px;
    }

    b,
    strong {
      font-weight: normal;
      font-family: $fontDemi;
    }
  }

  .sub-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 170px;
    height: 42px;
    padding: 0 20px;
    background: #aa198b;

    margin: 30px 0 0 0;
    border-radius: 100px;
    cursor: pointer;

    * {
      transition: all 0.2s ease;
    }

    &:hover {
      span {
        transform: translateX(5px);
      }
    }

    p {
      color: white;
      font-size: 15px;
      font-family: $fontR;
    }
  }

  .left-side {
    margin-left: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media screen and (max-width: 670px) {
      margin-left: 0;
      align-items: center;
      padding: 25px;
    }
  }
}
</style>
