<template>
  <div class="webinar_room">
    <div class="main-video-wrapper">
      <iframe
        ref="video"
        width="560"
        height="315"
        :src="`${content.blocks[currentVideo].link}`"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>

      <img
        v-if="previewShow"
        @click="triggerVideo()"
        class="preview"
        :src="`${$root.globalUrl}${content.blocks[currentVideo].preview}`"
        alt=""
      />
      <template v-for="(key, value) in content.blocks">
        <countdown
          v-if="currentVideo === value"
          v-bind:key="value.id"
          :end-time="content.blocks[value].publishTime"
        >
          <div class="timer-wrapper" slot="process" slot-scope="{ timeObj }">
            <div class="time-title">Трансляція розпочнеться через</div>
            <span
              class="time-wrapper"
              v-html="
                `<span class='day'>${timeObj.d} <span>${declOfNum(timeObj.d, [
                  'День',
                  'Дні',
                  'Днів'
                ])}</span> </span> <span class='hour'>${
                  timeObj.h
                }<span>${declOfNum(timeObj.h, [
                  'Година',
                  'Години',
                  'Годин'
                ])}</span></span><span class='separator'>:</span><span class='minutes'>${
                  timeObj.m
                } <span>${declOfNum(timeObj.m, [
                  'Хвилина',
                  'Хвилини',
                  'Хвилин'
                ])}</span></span><span class='separator'>:</span><span class='minutes'>${
                  timeObj.s
                } <span>${declOfNum(timeObj.s, [
                  'Секунда',
                  'Секунди',
                  'Секунд'
                ])}</span></span>`
              "
            >
            </span>
            <div class="time-thanks">Приємного перегляду</div>
          </div>

          <div slot="finish" class="finish"></div>
        </countdown>
      </template>
    </div>

    <div class="under-video">
      <div class="video-counter">
        <span>Відео {{ currentVideo + 1 }}</span> / {{ content.blocks.length }}
      </div>
      <div class="double-block">
        <div class="info">
          <div
            class="content-title"
            v-html="content.blocks[currentVideo].videoName"
          ></div>
          <div
            class="double-title"
            v-html="content.blocks[currentVideo].programTitle"
          ></div>
          <div
            class="text-wrapper"
            v-html="content.blocks[currentVideo].programText"
          ></div>
        </div>
        <div
          class="more-video video-list-wrapper"
          v-if="content.blocks.length !== 0 && content.blocks.length !== 1"
        >
          <div
            class="item"
            @click="switchVideo(value)"
            v-for="(key, value) in content.blocks"
            v-bind:class="[{ active: currentVideo === value }]"
            :key="value.id"
          >
            <div class="video-img">
              <div class="click-item"></div>

              <img
                v-if="key.preview !== undefined"
                class="preview"
                :src="`${$root.globalUrl}${key.preview}`"
                alt=""
              />

              <iframe
                v-else
                width="560"
                height="315"
                :src="`${key.link}`"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>

            <div class="description">{{ key.videoName }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="promo" v-html="content.blocks[currentVideo].promonumber"></div>
  </div>
</template>

<script>
export default {
  name: "webinar_room",
  props: {
    content: Object
  },
  data: () => ({
    nameDay: "",
    nameHour: "",
    nameMinutes: "",
    currentVideo: 0,
    videoArray: [],
    previewShow: true
  }),

  methods: {
    declOfNum(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : cases[number % 10 < 5 ? number % 10 : 5]
      ];
    },

    switchVideo(id) {
      this.currentVideo = id;
    },

    triggerVideo() {
      this.previewShow = !this.previewShow;
      this.$refs.video.src += "?&autoplay=1";
    }
  },

  mounted() {
    this.content.blocks.forEach(el => {
      // eslint-disable-next-line no-undef
      el.publishTime = moment(el.publishTime).format();
    });
  }
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";

.webinar_room {
  width: 100%;
  height: auto;
  margin: 15px 0;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 861px) {
    flex-direction: column;
    margin: 15px 0 15px 0;
  }

  @media screen and (max-width: 425px) {
    margin: 15px 0 15px 0;
  }

  .promo {
    margin-top: 15px;
  }

  .description-wrapper {
    padding-top: 20px;
    margin-bottom: 40px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 31px;
      height: 3px;
      background: #00a03b;
    }

    .content-title {
      color: #00a03b;
      font-family: $fontDemi;
      font-size: 30px;
      margin-bottom: 20px;

      @media screen and (max-width: 670px) {
        font-size: 18px;
      }
    }

    .double-block {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 425px) {
        flex-direction: column;
        justify-content: flex-start;
      }

      .text-wrapper {
        ::v-deep ul {
          li {
            padding-left: 22px;
            list-style: none;
            margin-bottom: 10px;
            position: relative;

            font-size: 16px;
            color: #545454;
            font-family: $fontR;

            @media screen and (max-width: 670px) {
              font-size: 14px;
            }

            &:before {
              content: "";
              width: 10px;
              height: 10px;
              border-radius: 50%;
              border: 1px solid #00a070;
              position: absolute;
              left: 0;
              top: 5px;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        ::v-deep p {
          color: #545454;
          font-family: $fontR;
          font-size: 16px;
          @media screen and (max-width: 670px) {
            font-size: 14px;
          }
        }
      }

      > div {
        max-width: 350px;
        width: 100%;

        @media screen and (max-width: 425px) {
          max-width: 425px;
          margin-bottom: 15px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        .double-title {
          color: #00a03b;
          font-family: $fontDemi;
          font-size: 20px;
          margin-bottom: 12px;

          @media screen and (max-width: 670px) {
            font-size: 16px;
          }
        }

        &:first-child {
          margin-right: 15px;

          @media screen and (max-width: 425px) {
            margin-right: 0;
          }
        }

        &:last-child {
          margin-left: 15px;
          @media screen and (max-width: 425px) {
            margin-left: 0;
          }
        }
      }
    }
  }

  .main-video-wrapper {
    width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    .timer-wrapper {
      background: #e4eaed;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      flex-direction: column;

      .time-title {
        color: #317575;
        font-family: $fontB;
        font-size: 33px;
        text-align: center;
        margin-bottom: 40px;

        @media screen and (max-width: 650px) {
          font-size: 22px;
          margin-bottom: 20px;
        }
        @media screen and (max-width: 425px) {
          font-size: 24px;
        }
      }

      .time-thanks {
        display: flex;
        order: 3;
        color: #317575;

        font-family: $fontDemi;
        font-size: 20px;
        text-align: center;
        margin-top: 32px;

        @media screen and (max-width: 650px) {
          font-size: 16px;
          margin-top: 16px;
        }
      }

      ::v-deep .time-wrapper {
        display: flex;
        flex-direction: row;
        order: 2;

        .finish {
          display: none !important;
        }

        .separator {
          color: #317575;
          font-size: 64px;
          font-family: $fontR;

          @media screen and (max-width: 650px) {
            font-size: 32px;
          }
        }

        .day {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #317575;
          font-size: 64px;
          font-family: $fontR;
          margin-right: 32px;
          @media screen and (max-width: 650px) {
            font-size: 32px;
            margin-right: 16px;
          }

          span {
            font-size: 16px;
            @media screen and (max-width: 650px) {
              font-size: 12px;
            }
          }
        }

        .hour {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #317575;
          font-size: 64px;
          font-family: $fontR;
          @media screen and (max-width: 650px) {
            font-size: 32px;
          }

          span {
            font-size: 16px;
            @media screen and (max-width: 650px) {
              font-size: 12px;
            }
          }
        }

        .minutes {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #317575;
          font-size: 64px;
          font-family: $fontR;
          @media screen and (max-width: 650px) {
            font-size: 32px;
          }

          span {
            font-size: 16px;
            @media screen and (max-width: 650px) {
              font-size: 12px;
            }
          }
        }
      }
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }

    .preview {
      width: 100%;
      height: auto;
      display: block;

      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .under-video {
    margin-top: 20px;

    @media screen and (max-width: 650px) {
      flex-direction: column;
    }

    .double-block {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      .info {
        max-width: 400px;
        width: 100%;

        @media screen and (max-width: 600px) {
          max-width: 600px;
        }
        margin-bottom: 40px;
        position: relative;

        .content-title {
          color: #00a03b;
          font-family: $fontDemi;
          font-size: 30px;
          margin-bottom: 20px;

          @media screen and (max-width: 670px) {
            font-size: 18px;
          }
        }

        .double-title {
          color: #00a03b;
          font-family: $fontDemi;
          font-size: 20px;
          margin-bottom: 12px;

          @media screen and (max-width: 670px) {
            font-size: 16px;
          }
        }

        .text-wrapper {
          ::v-deep ul {
            li {
              padding-left: 22px;
              list-style: none;
              margin-bottom: 10px;
              position: relative;

              font-size: 16px;
              color: #545454;
              font-family: $fontR;

              @media screen and (max-width: 670px) {
                font-size: 14px;
              }

              &:before {
                content: "";
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid #00a070;
                position: absolute;
                left: 0;
                top: 5px;
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          ::v-deep p {
            color: #545454;
            font-family: $fontR;
            font-size: 16px;
            @media screen and (max-width: 670px) {
              font-size: 14px;
            }
          }
        }
      }

      > div {
        &:first-child {
          margin-right: 15px;
          @media screen and (max-width: 600px) {
            margin-right: 0;
          }
        }

        &:last-child {
          margin-left: 15px;
          @media screen and (max-width: 600px) {
            margin-left: 0;
          }
        }
      }
    }

    .video-counter {
      color: #878787;
      font-size: 13px;
      font-family: $fontR;

      @media screen and (max-width: 650px) {
        margin-bottom: 20px;
      }

      span {
        color: #00a03b;
        font-family: $fontDemi;
      }
    }

    .more-video {
      max-width: 400px;
      width: 100%;

      @media screen and (max-width: 650px) {
        max-width: 650px;
      }

      &.video-list-wrapper::-webkit-scrollbar-track {
        background-color: #e4e4e4;
        border-radius: 50px;
      }

      &.video-list-wrapper::-webkit-scrollbar {
        width: 4px;
        background-color: #f5f5f5;
      }

      &.video-list-wrapper::-webkit-scrollbar-thumb {
        border-radius: 50px;
        background-color: #00a03b;
      }

      &.video-list-wrapper {
        max-height: 400px;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;

        .item {
          cursor: pointer;
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          transition: all 0.2s ease;

          &.active,
          &:hover {
            background: #ededed;
          }

          .video-img {
            max-width: 150px;
            min-width: 150px;
            width: 100%;
            height: auto;
            position: relative;
            overflow: hidden;

            @media screen and (max-width: 650px) {
              max-width: 120px;
              min-width: 120px;
            }

            .click-item {
              z-index: 3;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            }

            iframe {
              z-index: 1;
              display: block;
              width: 100%;
              position: relative;
              height: 90px;
              @media screen and (max-width: 650px) {
                height: 70px;
              }
            }

            .preview {
              z-index: 2;
              width: 100%;
              height: auto;
              display: block;
            }
          }

          .description {
            padding: 5px 15px;
            color: #545454;
            font-family: $fontR;
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
