<template>
  <div class="section-pharmacy-info">
    <div class="pharmacy-wrapper">
      <div>
        <h3>Інформація для аптек</h3>
        <div v-html="getPharmacy()"></div>
      </div>
    </div>

    <div class="distributor-wrapper">
      <div>
        <h3>Інформація для дистриб`юторів</h3>

        <div class="row-wrapper">
          <div
            v-for="(key, value) of getDistributors()"
            :key="key"
          >
            <div class="text" v-html="key"></div>
          </div>

          <!--          <div>-->
          <!--            <h4>БАДМ</h4>-->
          <!--            <p>Леся Довгоп’ят</p>-->
          <!--            <p>+380445947139, </p>-->
          <!--            <p><a href="mailto:Lesya.Dovgopyata@actavis.com">Lesya.Dovgopyata@actavis.com</a></p>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-pharmacy-info",
  props: {
    content: Array
  },
  methods: {
    getPharmacy() {
      return JSON.parse(this.content.find(item => item.unique_key === 'trade_activity_text_for_pharmacy').value).text
    },
    getDistributors() {
      return JSON.parse(this.content.find(item => item.unique_key === 'trade_activity_for_distributors').value).distributors
    }
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-pharmacy-info {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  @media screen and (max-width: 670px) {
    flex-direction: column;
  }

  > div {
    max-width: 400px;
    width: 100%;

    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 670px) {
      max-width: 670px;
    }

    > div {
      margin: 25px;
      padding-top: 25px;
      position: relative;

      &:before {
        content: "";
        width: 41px;
        height: 3px;
        background: white;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    &:nth-child(1) {
      margin-right: 15px;

      @media screen and (max-width: 670px) {
        margin-right: 0;
        margin-bottom: 15px;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 0;
      }
    }

    &.pharmacy-wrapper {
      background: #00a03b;

      > div {
        h3 {
          color: white;
          font-size: 20px;
          font-family: $fontDemi;
          margin-bottom: 10px;
        }

        p {
          color: #ffffff;
          font-family: $fontR;
          font-size: 14px;
        }

        a {
          color: white;
          text-decoration: underline;
        }
      }
    }

    &.distributor-wrapper {
      background: #317575;

      > div {
        h4 {
          font-family: $fontDemi;
          font-size: 13px;
          color: white;
          margin-bottom: 10px;
        }

        h3 {
          color: white;
          font-size: 20px;
          font-family: $fontDemi;
          margin-bottom: 10px;
        }

        p {
          color: #ffffff;
          font-family: $fontR;
          font-size: 13px;

          a {
            color: white;
          }
        }

        .row-wrapper {
          display: flex;
          flex-flow: row wrap;

          @media screen and (max-width: 425px) {
            flex-direction: column;
          }

          > div {
            max-width: 40%;
            width: 100%;
            margin-bottom: 15px;
            margin-right: 30px;

            @media screen and (max-width: 425px) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
