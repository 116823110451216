<template>
  <div class="comp-header">
    <div class="header-block">
      <div class="header-content">
        <div class="logo">
          <router-link to="/">
            <svg
              viewBox="0 0 90 31"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M83.6534 7.77443V9.03321C81.7141 7.52267 79.3341 6.7674 76.7778 6.7674C70.1666 6.7674 64.9658 12.0543 64.9658 18.8518C64.9658 21.8729 66.0236 24.7261 68.051 26.908C70.2548 29.3417 73.6044 30.8522 77.1304 30.8522C79.5985 30.8522 81.8023 30.0969 83.7415 28.5864V29.8452C83.7415 30.0969 84.006 30.3487 84.2704 30.3487H89.4712C89.7357 30.3487 90.0001 30.0969 90.0001 29.8452V7.77443C90.0001 7.52267 89.7357 7.27091 89.4712 7.27091H84.2704C83.9178 7.27091 83.6534 7.43875 83.6534 7.77443ZM84.1823 18.8518C84.1823 21.9568 81.7141 25.0618 77.8356 25.0618C74.1333 25.0618 71.4888 22.3764 71.4888 18.8518C71.4888 14.9076 74.4859 12.6417 77.7474 12.6417C81.7141 12.5578 84.1823 15.6628 84.1823 18.8518Z"
                fill="#005A85"
              />
              <path
                d="M14.8972 25.2296C13.3986 25.2296 12.429 24.81 11.7238 23.9708C11.1949 23.3834 10.9305 22.4603 10.9305 21.1176V12.5578H17.6298C17.8942 12.5578 18.1587 12.3061 18.1587 12.0543V7.69052C18.1587 7.43876 17.8942 7.187 17.6298 7.187H10.9305V1.31266C10.9305 1.0609 10.666 0.809143 10.4016 0.809143H4.93634C4.58374 0.809143 4.40744 1.0609 4.40744 1.31266V7.187H0.528893C0.264447 7.187 0 7.43876 0 7.69052V12.0543C0 12.3061 0.264447 12.5578 0.528893 12.5578H4.40744V20.5302C4.40744 23.803 4.93634 25.8171 6.17042 27.4115C7.84525 29.5934 10.7542 30.7683 14.4564 30.7683C17.3653 30.7683 19.2165 30.097 20.1861 29.3417C20.4505 29.0899 20.5387 28.6703 20.3624 28.4186C20.1861 28.2507 17.9824 24.81 17.9824 24.81C17.8061 24.4744 17.3653 24.3904 17.0127 24.6422C16.4838 24.894 15.6023 25.2296 14.8972 25.2296Z"
                fill="#005A85"
              />
              <path
                d="M30.6758 6.7674C27.3262 6.7674 24.3291 8.02618 22.1254 10.3759C20.098 12.5578 18.952 15.5789 18.952 18.7678C18.952 25.8171 24.1528 30.7683 31.4692 30.7683C34.7307 30.7683 37.904 29.5934 40.1959 27.7472C40.2841 27.6633 40.3722 27.5794 40.5485 27.4115C40.7248 27.2437 40.7248 26.908 40.6367 26.7402C40.1078 25.9849 38.7855 24.3065 38.0803 23.4673C37.904 23.2156 37.5514 23.2156 37.287 23.3834C37.1107 23.5512 36.9344 23.6352 36.7581 23.803C35.7003 24.6422 33.9373 25.6492 31.6455 25.6492C28.2077 25.6492 25.6513 23.4673 25.2987 20.2784H41.1656C41.5182 20.2784 41.8707 20.0266 41.9589 19.607C41.9589 19.2714 42.047 18.9357 42.047 18.4322C42.047 15.2432 40.9011 12.3061 38.8737 10.1242C36.7581 7.94226 33.8492 6.7674 30.6758 6.7674ZM30.6758 11.8025C32.7033 11.8025 35.0833 13.3131 35.3477 15.7468H25.6513C26.0039 13.8166 27.9432 11.8025 30.6758 11.8025Z"
                fill="#005A85"
              />
              <path
                d="M53.859 24.894V21.6211C53.859 20.2784 54.1235 18.7679 54.6523 17.2573C55.2694 15.495 56.1509 14.0684 57.1205 12.9774L58.619 11.1312V18.8518L53.859 24.894Z"
                fill="#00A070"
              />
              <path
                d="M58.7073 11.0473L61.8806 7.10313C62.1451 6.76745 62.5858 6.68353 62.9384 6.76745C63.291 6.93529 63.5555 7.27097 63.5555 7.69056V12.5579L58.7073 18.684V11.0473Z"
                fill="#B5D16C"
              />
              <path
                d="M63.5555 12.6418V15.4111C63.6436 16.8377 63.3792 18.5161 62.7621 20.1945C62.2332 21.7051 61.528 23.0478 60.6466 24.1387L58.7073 26.5724V18.8518L63.5555 12.6418Z"
                fill="#317575"
              />
              <path
                d="M58.7072 18.7678V26.4884L55.5338 30.4326C55.2694 30.7683 54.8286 30.8522 54.4761 30.6844C54.1235 30.5165 53.859 30.1808 53.859 29.7612V24.81L58.7072 18.7678Z"
                fill="#005A85"
              />
              <path
                d="M53.2418 24.894V21.6211C53.2418 20.2784 52.9774 18.7679 52.4485 17.2573C51.8315 15.495 50.95 14.0684 49.9803 12.9774L48.4818 11.1312V18.8518L53.2418 24.894Z"
                fill="#00A070"
              />
              <path
                d="M48.3937 11.0473L45.2204 7.10313C44.9559 6.76745 44.5152 6.68353 44.1626 6.76745C43.81 6.93529 43.5455 7.27097 43.5455 7.69056V12.5579L48.3937 18.684V11.0473Z"
                fill="#B5D16C"
              />
              <path
                d="M43.5456 12.6418V15.4111C43.4574 16.8377 43.7219 18.5161 44.3389 20.1945C44.8678 21.7051 45.573 23.0478 46.4545 24.1387L48.3938 26.5724V18.8518L43.5456 12.6418Z"
                fill="#317575"
              />
              <path
                d="M48.3938 18.7678V26.4884L51.5671 30.4326C51.8316 30.7683 52.2723 30.8522 52.6249 30.6844C52.9775 30.5165 53.242 30.1808 53.242 29.7612V24.81L48.3938 18.7678Z"
                fill="#005A85"
              />
            </svg>
          </router-link>
        </div>

        <p class="text">
          Шановний <span>Колего</span>, ці маркетингові активності для вас!
        </p>
      </div>
    </div>

    <div class="mobile-btn-wrapper">
      <!--      <div v-if="isOpenFilter === false && $route.name !== 'home'" v-bind:class="[{ active: isOpenMenu }]"-->
      <!--           class="spec-btn" @click="openMenu()">-->

      <!--        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--          <path class="line" d="M2.5094 1L18.1259 1" stroke="#317575" stroke-width="0.806641" stroke-linecap="round"/>-->
      <!--          <path class="line" d="M2.50879 17.2874L18.1253 17.2874" stroke="#317575" stroke-width="0.806641"-->
      <!--                stroke-linecap="round"/>-->
      <!--          <path d="M18.1325 9.16614L2.40665 9.16614" stroke="#317575" stroke-width="1.61328" stroke-linecap="round"/>-->
      <!--          <path d="M5.38901 12.5641L2 9.14379L5.38901 5.7235" stroke="#317575" stroke-width="1.61328"-->
      <!--                stroke-linecap="round"/>-->
      <!--        </svg>-->

      <!--        <p>Спеціальність</p>-->

      <!--      </div>-->

      <!--      <div @click="openFilter()"-->
      <!--           v-bind:class="[{ active: isOpenFilter }]"-->
      <!--           v-if="isOpenMenu !== true && $route.name === 'page'"-->
      <!--           class="filter-btn">-->

      <!--        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--          <path d="M19 1H1L8.20026 9.40999V15.2217L11.7997 17V9.40999L19 1Z" stroke="#317575" stroke-width="0.81"-->
      <!--                stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--          <path d="M6 3.5H14" stroke="#317575" stroke-width="1.61" stroke-linecap="round" stroke-linejoin="round"/>-->
      <!--        </svg>-->

      <!--        <p>Фільтр </p>-->

      <!--      </div>-->

      <div
        @click="backBtn()"
        class="back-btn"
        v-if="isOpenMenu !== true && $route.name === 'article-page'"
      >
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.1325 9.16614L2.40665 9.16614"
            stroke="#317575"
            stroke-width="1.61328"
            stroke-linecap="round"
          />
          <path
            d="M5.38901 12.5641L2 9.14379L5.38901 5.7235"
            stroke="#317575"
            stroke-width="1.61328"
            stroke-linecap="round"
          />
        </svg>

        <p>До каталогу</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "header-block",
  props: {},

  data: function() {
    return {
      isOpenMenu: false,
      isOpenFilter: false,
      all: "Всі",
      test: [],

      category: [
        {
          name: "Педіатр",
          params: "pediatrician",
          id: "1",
          title: "Для педіатрів - Rx-портал Тева"
        },
        {
          name: "Терапевт",
          params: "therapist",
          id: "2",
          title: "Для терапевтів - Rx-портал Тева"
        },
        {
          name: "Отоларинголог",
          params: "otolaryngologist",
          id: "3",
          title: "Для отоларингологів - Rx-портал Тева"
        },
        {
          name: "Невропатолог",
          params: "neurologist",
          id: "4",
          title: "Для невропатологів - Rx-портал Тева"
        },
        {
          name: "Пульмонолог",
          params: "pulmonologist",
          id: "5",
          title: "Для пульмонологів - Rx-портал Тева"
        },
        {
          name: "Фармацевт",
          params: "pharmacist",
          id: "6",
          title: "Для фармацевтів - Rx-портал Тева"
        },
        {
          name: "Дистриб’ютор",
          params: "distributor",
          id: "7",
          title: ""
        },
        {
          name: "Психіатр",
          params: "psychiatrist",
          id: "8",
          title: "Для психіатрів - Rx-портал Тева\n"
        },
        {
          name: "Кардіолог",
          params: "cardiologist",
          id: "9",
          title: "Для кардіологів - Rx-портал Тева"
        },
        {
          name: "Ендокринолог",
          params: "endocrinologist",
          id: "11",
          title: "Для ендокринологів - Rx-портал Тева"
        },
        {
          name: "Ревматолог",
          params: "rheumatologist",
          id: "12",
          title: "Для ревматологів - Rx-портал Тева"
        },
        {
          name: "Гінеколог",
          params: "gynecologist",
          id: "13",
          title: "Для гінекологів - Rx-портал Тева"
        },
        {
          name: "Дерматолог",
          params: "dermatologist",
          id: "14",
          title: "Для дерматологів - Rx-портал Тева"
        },
        {
          name: "Травматолог",
          params: "traumatologist",
          id: "15",
          title: "Для травматологів - Rx-портал Тева"
        },
        {
          name: "Хірург",
          params: "surgeon",
          id: "16",
          title: "Для хірургів - Rx-портал Тева"
        },
        {
          name: "Інфекціоніст",
          params: "infectious",
          id: "17",
          title: "Для інфекціоністів - Rx-портал Тева"
        }
      ]
    };
  },

  methods: {
    backBtn() {
      this.$router.push({
        name: "page",
        params: { pageNum: "1", category: this.$route.params.category }
      });
    },
    openMenu() {
      this.isOpenMenu = !this.isOpenMenu;
    },

    openFilter() {
      this.$emit("clicked");
      this.isOpenFilter = !this.isOpenFilter;
    }
  }
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;

.comp-header {
  * {
    user-select: none;
  }
}

.header-block {
  z-index: 15;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #ffffff;
  box-shadow: 0 4px 49px rgba(0, 0, 0, 0.09);
  height: 65px;

  @media screen and (max-width: 425px) {
    height: 50px;
  }

  .header-content {
    margin: 0 auto;
    max-width: $maxW;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and(max-width: $maxW) {
      padding: 0 10px;
    }

    @media screen and (max-width: 425px) {
      height: 50px;
    }

    .open-menu {
      cursor: pointer;

      @media screen and (max-width: 425px) {
        display: none;
      }

      svg {
        &.active {
          .line {
            opacity: 0;
          }

          .arrow {
            transform: translateX(-23px) rotate(180deg);
          }
        }
      }

      .arrow,
      .line {
        transition: all 0.2s ease;
        transform-origin: 80% 50%;
      }
    }

    .text {
      font-size: 32px;
      font-family: $fontR;
      color: #545454;

      @media screen and (max-width: 1024px) {
        font-size: 22px;
      }
      @media screen and (max-width: 800px) {
        font-size: 16px;
      }
      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
      @media screen and (max-width: 425px) {
        display: none;
      }

      span {
        color: #00a03b;
        font-family: $fontDemi;
        text-decoration: underline;
      }
    }
  }

  .logo {
    svg {
      max-width: 90px;
      width: 100%;
      @media screen and (max-width: 425px) {
        max-width: 60px;
      }
    }
  }
}

.mobile-btn-wrapper {
  position: fixed;
  z-index: 15;
  left: 0;
  top: 50px;
  display: none;
  width: 100%;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;

    max-width: 100%;
    width: 100%;
    background: #e4eaed;
    transition: all 0.2s ease;

    * {
      transition: all 0.4s ease;
    }

    &.spec-btn {
      &.active {
        max-width: 425px;

        .line {
          opacity: 0;
        }
      }
    }

    &.filter-btn {
      margin: 0 0 0 auto;

      &.active {
        max-width: 425px !important;
      }

      p {
        span {
          font-family: $fontDemi;
        }
      }
    }

    &:first-child {
      border-right: 1px solid white;
    }

    svg {
      margin-right: 10px;
    }

    p {
      color: #317575;
      font-family: $fontR;
      font-size: 11px;
    }
  }

  @media screen and (max-width: 425px) {
    display: flex;
  }
}

.drop-menu {
  z-index: 10;
  position: fixed;
  left: 0;
  top: 65px;
  width: 100%;
  background: rgba(241, 241, 241, 0.9);
  backdrop-filter: blur(5px);
  transform: translateY(-150%);
  transition: all 0.7s ease;

  .menu-title {
    padding: 0 10px;

    p {
      color: #545454;
      font-family: $fontDemi;
      font-size: 28px;

      @media screen and(max-width: 670px) {
        font-size: 20px;
      }
    }

    margin-bottom: 24px;

    @media screen and(max-width: 670px) {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 425px) {
    top: 90px;
    height: calc(100vh - 90px);
  }

  &.active {
    transform: translateY(0);
  }

  .drop-menu-content {
    max-width: $maxW;
    margin: 0 auto;
    padding: 40px 0;

    @media screen and (max-width: 425px) {
      padding: 15px 0;
    }

    .list-menu {
      display: flex;
      flex-flow: row wrap;
      padding: 0 10px;

      @media screen and (max-width: 425px) {
        flex-flow: column nowrap;
      }

      .item {
        cursor: pointer;
        padding-left: 60px;
        position: relative;

        @media screen and (max-width: 425px) {
          padding-left: 0;
          width: max-content;
        }

        &:before {
          content: "";
          width: 45px;
          height: 1px;
          background: #545454;
          position: absolute;
          left: 8px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          @media screen and (max-width: 425px) {
            display: none;
          }
        }

        &:after {
          content: "";
          display: none;
          width: 45px;
          height: 1px;
          background: #545454;
          position: absolute;
          right: -53px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          @media screen and (max-width: 425px) {
            display: block;
          }
        }

        &:first-child {
          padding-left: 0;

          &:before {
            display: none;
          }
        }

        &:hover,
        &.active {
          &:before,
          &:after {
            @media screen and (max-width: 425px) {
              background: #00a03b;
            }
          }

          p {
            color: #00a03b;
          }
        }

        p {
          line-height: 45px;
          transition: all 0.2s ease;
          color: #545454;
          font-size: 32px;
          font-family: $fontDemi;
          @media screen and (max-width: 670px) {
            font-size: 24px;
          }
        }
      }
    }
  }
}

.filter-section {
  z-index: 20;
  background: rgba(241, 241, 241, 0.95);
  backdrop-filter: blur(5px);
  position: fixed;
  left: 0;
  top: 90px;
  width: 100%;
  min-height: calc(100vh);
  height: calc(100% - 90px);

  overflow: scroll;
  -webkit-overflow-scrolling: touch;

  display: none !important;
  flex-direction: column;
  justify-content: flex-start;

  @media screen and (max-width: 425px) {
    display: flex !important;
  }

  .filer-line {
    padding: 0 15px;
    display: flex;
    flex-flow: row wrap;

    .item {
      margin-right: 8px;
      background: #e4eaed;
      border-radius: 50px;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      span {
        cursor: pointer;
      }

      p {
        margin-right: 20px;
        color: #005a85;
        font-family: $fontDemi;
        font-size: 9px;
      }

      .container {
        position: relative;
        cursor: pointer;
        user-select: none;
      }

      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }

    &.filer-drug {
      margin-top: 20px;
    }

    &.filer-category {
      img {
        max-width: 14px;
        width: 100%;
        height: auto;
        display: block;
        margin-right: 5px;
      }
    }
  }

  .drop-list-block {
    display: flex;
    flex-direction: column;

    .container {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding-left: 30px;
      margin: 6px 15px 6px 0;
      cursor: pointer;
      user-select: none;
      height: 21px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      .icon {
        margin-right: 5px;
        display: block;
        width: 14px;
        height: 14px;
      }

      p {
        color: #005a85;
        font-family: $fontDemi;
        font-size: 13px;
      }
    }

    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 21px;
      width: 21px;
      border: 1px solid #cecece;
    }

    .container input:checked ~ .checkmark {
      border: 1px solid #005a85;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .container input:checked ~ .checkmark:after {
      display: block;
    }

    .container .checkmark:after {
      left: 6px;
      top: 2px;
      width: 6px;
      height: 9px;
      border: solid #005a85;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .drop-list {
      border-top: 1px solid #dddddd;
      max-height: 50px;
      overflow: hidden;
      transition: all 0.4s ease;
      padding: 0 15px 15px 15px;

      * {
        transition: all 0.4s ease;
      }

      .container {
        margin-bottom: 15px;
      }

      &.active {
        max-height: 1000px;

        .name-wrapper {
          svg {
            transform: rotate(180deg);
          }
        }
      }

      .name-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;

        h3 {
          color: #005a85;
          font-family: $fontR;
          font-size: 16px;
          font-weight: normal;
        }
      }
    }
  }

  .btn-wrapper {
    width: 100%;
    padding: 15px;
    background: white;
    box-shadow: 0 4px 49px rgba(0, 0, 0, 0.09);
    margin: auto 0 0 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 131px;
      height: 38px;

      display: flex;
      justify-content: center;
      align-items: center;

      &.reset-all {
        background: #a3b0b7;
        border-radius: 61.9048px;
      }

      &.show {
        background: #00a03b;
        border-radius: 61.9048px;
      }

      p {
        font-size: 11px;
        font-family: $fontR;
        color: white;

        span {
          font-family: $fontDemi;
        }
      }
    }
  }
}
</style>
