<template>
  <div class="section-stocks-description">
    <div class="stock-list">
      <h3>У період акції:</h3>
      <p v-html="content.promotion_title"></p>
    </div>

    <div class="stock-list">
      <p>
        <b>Період первинних відвантажень:</b>
        <span v-html="content.promotion_time"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-stocks-description",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-stocks-description {
  margin: 15px 0;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }

  .stock-list {
    position: relative;
    padding-top: 25px;
    margin-bottom: 25px;

    @media screen and (max-width: 670px) {
      padding-top: 15px;
      margin-bottom: 15px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      font-size: 16px;
      font-family: $fontDemi;
      color: #545454;
      margin-bottom: 15px;
      font-weight: normal;
    }

    p {
      margin-bottom: 15px;
      font-size: 16px;
      font-family: $fontR;
      color: #545454;
      @media screen and (max-width: 670px) {
        font-size: 14px;
      }

      b,
      strong {
        display: block;
        color: #00a03b;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 41px;
      height: 3px;
      background: #00a03b;
    }
  }
}
</style>
