<template>
  <div class="section-row-text-padding">
    <div class="line" v-for="(key, value) in dataContent" :key="value">
      <div
        class="normal-block"
        v-for="(secondKey, value2) in key"
        :key="value2"
      >
        <p><b v-html="secondKey.title"> </b></p>
        <p v-html="secondKey.description"></p>
      </div>

      <div class="item-hidden" v-if="key.length === 1"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-text-padding",
  props: {
    content: Object
  },
  data: () => ({
    dataContent: []
  }),
  beforeMount() {
    let array = this.content.blocks;
    let size = 2;
    for (let i = 0; i < Math.ceil(array.length / size); i++) {
      this.dataContent[i] = array.slice(i * size, i * size + size);
    }
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-row-text-padding {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
    flex-direction: column;
  }

  .line {
    display: flex;
    margin-bottom: 15px;

    @media screen and (max-width: 670px) {
      flex-direction: column;
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      padding-top: 25px;
      position: relative;
      max-width: 400px;
      width: 100%;
      margin-bottom: 15px;

      &.item-hidden {
        margin: 0;
        padding: 0;
        visibility: hidden;
      }

      @media screen and (max-width: 1024px) {
        max-width: 500px;
      }

      @media screen and (max-width: 670px) {
        padding-top: 15px;
        max-width: 670px;
      }

      &:before {
        content: "";
        width: 41px;
        height: 3px;
        background: #00a03b;
        position: absolute;
        left: 0;
        top: 0;
      }

      p {
        color: #545454;
        font-family: $fontR;
        font-size: 16px;
        margin-bottom: 15px;

        &:last-child {
          margin-bottom: 0;
        }

        @media screen and (max-width: 670px) {
          font-size: 14px;
        }

        b,
        strong {
          font-weight: normal;
          font-family: $fontDemi;
        }
      }

      &:nth-child(1) {
        margin-right: 15px;
        @media screen and (max-width: 670px) {
          margin-right: 0;
        }
      }

      &:nth-child(2) {
        margin-left: 15px;
        @media screen and (max-width: 670px) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
