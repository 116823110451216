<template>
  <div class="section-table-drug-comparison">
    <div class="left-table">
      <table border="0" cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <td colspan="1"></td>
            <td
              :colspan="`${content.table[0].left_row.length}`"
              :style="{ background: content.left_color }"
              v-html="content.left_title"
            ></td>
            <td
              :colspan="`${content.table[0].left_row.length}`"
              v-html="content.right_title"
            ></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(key, value) in content.table" :key="value + 'left-table'">
            <td v-html="key.annotation"></td>
            <td
              v-for="(secondKey, secondValue) in key.left_row"
              :key="secondValue + 'left-row-1'"
              v-html="secondKey"
              v-bind:class="[
                { separate: secondValue + 1 === key.left_row.length }
              ]"
            ></td>
            <td
              v-for="(secondKey, value2) in key.right_row"
              v-html="secondKey"
              :key="value2 + 'right-row-1'"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="header-wrapper">
      <div
        class="header-table header-table1"
        :style="{ background: content.left_color }"
      >
        <p v-html="content.left_title"></p>
      </div>
    </div>

    <div class="table-mob table-mob1">
      <table border="0" cellpadding="0" cellspacing="0">
        <tbody>
          <tr v-for="(key, value) in content.table" :key="value + 'table-mob1'">
            <td v-html="key.annotation"></td>
            <td
              v-for="(secondKey, value2) in key.left_row"
              :key="value2 + 'left-row-2'"
              v-html="secondKey"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="header-wrapper">
      <div
        class="header-table header-table2"
        :style="{ background: content.right_color }"
      >
        <p v-html="content.right_title"></p>
      </div>
    </div>

    <div class="table-mob table-mob2">
      <table border="0" cellpadding="0" cellspacing="0">
        <tbody>
          <tr v-for="(key, value) in content.table" :key="value + 'table-mob2'">
            <td v-html="key.annotation"></td>
            <td
              v-for="(secondKey, value2) in key.right_row"
              :key="value2 + 'right-row-2'"
              v-html="secondKey"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-table-drug-comparison",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-table-drug-comparison {
  margin: 15px 0;
  display: flex;
  @media screen and (max-width: 861px) {
    flex-direction: column;
    margin: 15px 0 15px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 15px -15px 15px 0;
  }

  .left-table {
    width: 100%;
    @media screen and (max-width: 861px) {
      display: none;
    }

    table {
      width: 100%;
    }

    thead {
      tr {
        td:nth-child(2) {
          padding: 12px 0;
          text-align: center;
          background: #00a03b;
          border-radius: 50px 0 0 50px;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;
          border-right: 15px solid white;
        }

        td:nth-child(3) {
          padding: 12px 0;
          text-align: center;
          background: #aa198b;
          border-radius: 0 50px 50px 0;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;
        }
      }
    }

    tbody {
      tr {
        transition: background-color 0.2s ease;

        &:hover {
          background: rgba(116, 116, 116, 0.3) !important;
        }

        &:nth-child(2n) {
          background: #ececec;
        }

        &:nth-child(2n + 1) {
          background: #f2f2f2;
        }

        td {
          border: none;
          color: #545454;
          font-family: $fontR;
          font-size: 13px;
          text-align: center;
          padding: 7px 10px;
          word-break: break-all;

          @media screen and (max-width: 1024px) {
            word-break: normal;
          }

          &:first-child {
            word-break: normal;
            width: 129px;
            font-family: $fontM;
            text-align: right;
            padding: 7px;
            border-right: 7px solid white;
          }

          &.separate {
            border-right: 15px solid white;
          }
        }

        &:first-child {
          td {
            font-family: $fontM;
          }
        }
      }
    }
  }

  .header-wrapper {
    overflow: hidden;

    .header-table {
      display: none;
      @media screen and (max-width: 861px) {
        display: flex;
        margin-bottom: 15px;
      }

      justify-content: flex-start;

      align-items: center;
      width: auto;
      margin-left: 129px;
      border-radius: 50px 0 0 50px;
      padding: 12px;
      @media screen and (max-width: 425px) {
        margin-right: 15px;
      }

      p {
        color: white;
        font-size: 14px;
        font-family: $fontDemi;
      }

      &.header-table1 {
        background: #00a03b;
      }

      &.header-table2 {
        background: #aa198b;
      }
    }
  }

  .table-mob {
    display: none;
    @media screen and (max-width: 861px) {
      display: block;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 670px) {
      overflow-x: auto;
      width: 100%;
      position: relative;
    }

    &.table-mob1 {
      thead {
        tr {
          td:nth-child(2) {
            position: relative;
            padding: 12px 0;
            text-align: center;
            background: #00a03b;
            border-radius: 50px 0 0 50px;
            color: white;
            font-family: $fontDemi;
            font-size: 16px;
            border-bottom: 7px solid white;
          }
        }
      }
    }

    &.table-mob2 {
      thead {
        tr {
          td:nth-child(2) {
            position: relative;
            padding: 12px 0;
            text-align: center;
            background: #aa198b;
            border-radius: 50px 0 0 50px;
            color: white;
            font-family: $fontDemi;
            font-size: 16px;
            border-bottom: 7px solid white;
          }
        }
      }
    }

    table {
      width: 100%;
      @media screen and (max-width: 670px) {
        min-width: 600px;
      }
    }

    thead {
      tr {
        td:nth-child(2) {
          padding: 12px 0;
          text-align: center;
          background: #00a03b;
          border-radius: 50px 0 0 50px;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;

          @media screen and (max-width: 861px) {
            text-align: left !important;
          }
        }

        td:nth-child(3) {
          padding: 12px 0;
          text-align: center;
          background: #aa198b;
          border-radius: 0 50px 50px 0;
          color: white;
          font-family: $fontDemi;
          font-size: 16px;
          border-bottom: 7px solid white;
          @media screen and (max-width: 861px) {
            text-align: left !important;
          }
        }
      }
    }

    tbody {
      tr {
        &:nth-child(2n) {
          background: #ececec;
        }

        &:nth-child(2n + 1) {
          background: #f2f2f2;
        }

        td {
          border: none;
          color: #545454;
          font-family: $fontR;
          font-size: 13px;
          text-align: center;
          padding: 7px 10px;

          &:nth-child(1) {
            @media screen and (max-width: 861px) {
              text-align: right !important;
            }
          }

          @media screen and (max-width: 861px) {
            text-align: left !important;
          }

          &:first-child {
            font-family: $fontM;
            text-align: right;
            padding: 7px;
            border-right: 7px solid white;
            width: 129px;
          }
        }

        &:first-child {
          td {
            font-family: $fontM;
          }
        }
      }
    }
  }
}
</style>
