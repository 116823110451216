<template>
  <div class="section-recommend">
    <h2 class="h3-title-recommend"></h2>

    <div class="item-wrapper">
      <div
        v-bind:style="{ background: key.color }"
        :class="`item`"
        v-for="(key, value) in content.blocks"
        :key="value"
      >
        <div class="img-wrapper">
          <img :src="`${$root.globalUrl}${key.file}`" alt="" />
        </div>
        <div class="text-wrapper">
          <p v-html="key.text"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-recommend",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "two_icons_with_background_text_right"
  })
};
</script>

<style scoped lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-recommend {
  margin: 15px 0;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
  }
  @media screen and (max-width: 425px) {
    margin: 15px -15px 15px 0;
    overflow-x: scroll;
    justify-content: flex-start;
    position: relative;
    width: auto;
  }

  .item-wrapper {
    display: flex;

    .item {
      max-width: 500px;
      display: flex;
      align-items: center;
      width: 100%;
      padding: 30px;
      margin-right: 15px;

      @media screen and (max-width: 425px) {
        max-width: 192px;
        min-width: 192px;
        width: 192px;
        flex-direction: column;
      }

      .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;

        @media screen and (max-width: 425px) {
          margin-right: 0;
          margin-bottom: 15px;
        }

        img {
          max-height: 50px;
          height: 100%;
          width: auto;
          display: block;
        }
      }

      p {
        color: white;
        font-size: 13px;
        font-family: $fontR;
        @media screen and (max-width: 425px) {
          text-align: center;
        }
      }

      &.color1 {
        background: #005a85;
      }

      &.color2 {
        background: #317575;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
