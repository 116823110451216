<template>
  <div class="section-row-blockquote">
    <div class="left" v-html="content.description"></div>
    <div class="right">
      <div class="text-wrapper">
        <blockquote v-html="content.quote"></blockquote>
        <span v-html="content.author"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-blockquote",
  props: {
    content: Object
  }
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-row-blockquote {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 670px) {
    margin: 15px 0;
    flex-direction: column;
  }

  > div {
    width: 100%;
    max-width: 400px;
    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }

    @media screen and (max-width: 670px) {
      max-width: 670px;
    }
    @media screen and (max-width: 425px) {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &:nth-child(1) {
      margin-right: 15px;
      @media screen and (max-width: 670px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 0;
      }
    }

    p {
      color: #545454;
      font-family: $fontR;
      font-size: 16px;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 670px) {
        font-size: 14px;
      }
    }

    .text-wrapper {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0 !important;
      }

      blockquote {
        @media screen and (max-width: 670px) {
          font-size: 16px;
          &:last-child {
            margin-bottom: 0;
          }
        }

        p {
          color: #00a03b;
          font-family: $fontM;
          font-weight: normal;
          font-style: normal;
          font-size: 18px;
          @media screen and (max-width: 670px) {
            font-size: 16px;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      span {
        color: #00a03b;
        margin-top: 5px;
        display: block;
        font-family: $fontR;
        font-size: 13px;
      }
    }
  }
}
</style>
