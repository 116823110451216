<template>
  <div class="section-row-drug-use">
    <div>
      <img :src="`${$root.globalUrl}${content.file}`" alt="" />
    </div>

    <div class="text-wrapper">
      <p v-for="(key, value) in content.blocks" :key="value">
        <b v-html="key.title"></b>
        <span v-html="key.description"></span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "section-row-drug-use",
  props: {
    content: Object
  },

  data: () => ({
    nameSection: "image_left_paragraph_right"
  })
};
</script>

<style lang="scss">
$fontB: "AvenirNextCyr-Bold";
$fontM: "AvenirNextCyr-Medium";
$fontR: "AvenirNextCyr-Regular";
$fontL: "AvenirNextCyr-Light";
$fontUL: "AvenirNextCyr-UltraLight";
$fontTh: "AvenirNextCyr-Thin";
$fontDemi: "AvenirNextCyr-Demi";
$maxW: 1108px;
.section-row-drug-use {
  display: flex;
  margin: 15px 0;
  min-height: 40px;

  @media screen and (max-width: 670px) {
    margin: 15px 0;
    flex-direction: column;
  }

  div {
    width: 100%;
    max-width: 400px;
    @media screen and (max-width: 1024px) {
      max-width: 500px;
    }
    @media screen and (max-width: 670px) {
      max-width: 670px;
    }

    &:nth-child(1) {
      margin-right: 15px;
      @media screen and (max-width: 670px) {
        margin-right: 0;
      }
    }

    &:nth-child(2) {
      margin-left: 15px;
      @media screen and (max-width: 670px) {
        margin-left: 0;
      }
    }
  }

  img {
    width: 100%;
    height: auto;
    display: block;
    margin-right: 15px;
    @media screen and (max-width: 670px) {
      margin-bottom: 15px;
      margin-right: 0;
    }
  }

  .text-wrapper {
    p {
      margin-bottom: 15px;
      color: #545454;
      font-family: $fontR;
      font-size: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      @media screen and (max-width: 670px) {
        font-size: 14px;
        &:last-child {
          margin-bottom: 0;
        }
      }

      strong {
        font-family: $fontDemi;
      }

      b {
        color: #00a03b;
        font-family: $fontDemi;
        font-weight: normal;
        display: block;
      }
    }
  }
}
</style>
