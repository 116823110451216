import "@babel/polyfill";
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import headerBlock from './components/header-block'
import footerBlock from './components/footer-block'
import subscribeBlock from './components/subscribe-block'
import subscribeBlockSecond from './components/subscribe-block-second'
import downloadApp from './components/download-app'

import sectionAppCarousel from './components/section-app-carousel'
import sectionBackgroundTextImg from './components/section-background-text-img'
import sectionBlockquoteList from './components/section-blockquote-list'
import sectionCalculateDrugType2 from './components/section-calculate-drug-type2'
import sectionCalculateDrug from './components/section-calculate-drug'
import sectionColImgText from './components/section-col-img-text'
import sectionColListText from './components/section-col-list-text'
import sectionDoubleColText from './components/section-double-col-text'
import sectionDoubleColTextIcon from './components/section-double-col-text-icon'
import sectionDoubleRowText from './components/section-double-row-text'
import sectionDoubleRowTextIcon from './components/section-double-row-text-icon'
import sectionLinks from './components/section-links'
import sectionList from './components/section-list'
import sectionDoubleList from './components/section-double-list'
import sectionListText from './components/section-list-text'
import sectionMainImg from './components/section-main-img'
import sectionPharmacyInfo from './components/section-pharmacy-info'
import sectionRef from './components/section-ref'
import sectionRegText from './components/section-reg-text'
import sectionRowBlockquote from './components/section-row-blockquote'
import sectionRowColText from './components/section-row-col-text'
import sectionRowDrugUse from './components/section-row-drug-use'
import sectionRowIconBackgroundText from './components/section-row-icon-background-text'
import sectionRowIconText from './components/section-row-icon-text'
import sectionRowImgText from './components/section-row-img-text'
import sectionRowListText from './components/section-row-list-text'
import sectionRowTextImg from './components/section-row-text-img'
import sectionRowTextPadding from './components/section-row-text-padding'
import sectionSliderWrapper from './components/section-slider-wrapper'
import sectionStocksDescription from './components/section-stocks-description'
import sectionTable from './components/section-table'
import sectionTableDrugComparison from './components/section-table-drug-comparison'
import sectionTableDrugComparisonColor from './components/section-table-drug-comparison-color'
import sectionTableDrugSingleComparison from './components/section-table-drug-single-comparison'
import sectionTableDrugSingleComparisonColor from './components/section-table-drug-single-comparison-color'
import sectionTextBigList from './components/section-text-big-list'
import sectionTextCarousel from './components/section-text-carousel'
import sectionDiagramType1 from './components/section-diagram-type1'
import sectionDiagramType2 from './components/section-diagram-type2'
import sectionDiagramType3 from './components/section-diagram-type3'
import sectionVideo360 from './components/section-video-360'
import sectionDrugDescription from './components/section-drug-description'
import sectionPicker from './components/section-picker'
import sectionVideo from './components/section-video'
import homeParalax from './components/home-paralax'
import contentPageParallax from './components/content-page-parallax'
import sectionStudyDesign from './components/section-study-design'
import sectionFullChart from './components/section-full-chart'
import sectionRecommend from './components/section-recommend'
import sectionDownload from './components/section-download'
import sectionRowDoubleIconBackgroundText from './components/section-row-double-icon-background-text'
import sectionSingleVideo from './components/section-single-video'
import preloader from './components/preloader'
import webinarRoom from './components/webinar_room'
import sectionImageLeftTextWithBackgroundRight from './components/section-image_left_text_with_background_right'
import sectionImageRightTextWithBackgroundLeft from './components/section-image_right_text_with_background_left'

import vueAwesomeCountdown from 'vue-awesome-countdown'


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

Vue.use(VueAwesomeSwiper);
Vue.use(vueAwesomeCountdown, 'vac');


Vue.component('headed-block', headerBlock);
Vue.component('home-paralax', homeParalax);
Vue.component('footer-block', footerBlock);
Vue.component('subscribe-block', subscribeBlock);
Vue.component('subscribe-block-second', subscribeBlockSecond);
Vue.component('download-app', downloadApp);
Vue.component('content-page-parallax', contentPageParallax);

Vue.component('section-image_left_text_with_background_right', sectionImageLeftTextWithBackgroundRight);
Vue.component('section-image_right_text_with_background_left', sectionImageRightTextWithBackgroundLeft);

Vue.component('section-app-carousel', sectionAppCarousel);
Vue.component('section-background-text-img', sectionBackgroundTextImg);
Vue.component('section-blockquote-list', sectionBlockquoteList);
Vue.component('section-calculate-drug-type2', sectionCalculateDrugType2);
Vue.component('section-calculate-drug', sectionCalculateDrug);
Vue.component('section-col-img-text', sectionColImgText);
Vue.component('section-col-list-text', sectionColListText);
Vue.component('section-double-col-text', sectionDoubleColText);
Vue.component('section-double-col-text-icon', sectionDoubleColTextIcon);
Vue.component('section-double-row-text', sectionDoubleRowText);
Vue.component('section-double-row-text-icon', sectionDoubleRowTextIcon);
Vue.component('section-list', sectionList);
Vue.component('section-double-list', sectionDoubleList);
Vue.component('section-list-text', sectionListText);
Vue.component('section-main-img', sectionMainImg);
Vue.component('section-pharmacy-info', sectionPharmacyInfo);
Vue.component('section-ref', sectionRef);
Vue.component('section-reg-text', sectionRegText);
Vue.component('section-row-blockquote', sectionRowBlockquote);
Vue.component('section-row-col-text', sectionRowColText);
Vue.component('section-row-drug-use', sectionRowDrugUse);
Vue.component('section-row-icon-background-text', sectionRowIconBackgroundText);
Vue.component('section-row-icon-text', sectionRowIconText);
Vue.component('section-row-img-text', sectionRowImgText);
Vue.component('section-row-list-text', sectionRowListText);
Vue.component('section-row-text-img', sectionRowTextImg);
Vue.component('section-row-text-padding', sectionRowTextPadding);
Vue.component('section-slider-wrapper', sectionSliderWrapper);
Vue.component('section-stocks-description', sectionStocksDescription);
Vue.component('section-table', sectionTable);
Vue.component('section-table-drug-comparison', sectionTableDrugComparison);
Vue.component('section-table-drug-comparison-color', sectionTableDrugComparisonColor);
Vue.component('section-table-drug-single-comparison', sectionTableDrugSingleComparison);
Vue.component('section-table-drug-single-comparison-color', sectionTableDrugSingleComparisonColor);
Vue.component('section-text-big-list', sectionTextBigList);
Vue.component('section-text-carousel', sectionTextCarousel);
Vue.component('section-diagram-type1', sectionDiagramType1);
Vue.component('section-diagram-type2', sectionDiagramType2);
Vue.component('section-diagram-type3', sectionDiagramType3);
Vue.component('section-video-360', sectionVideo360);
Vue.component('section-drug-description', sectionDrugDescription);
Vue.component('section-picker', sectionPicker);
Vue.component('section-video', sectionVideo);
Vue.component('section-study-design', sectionStudyDesign);
Vue.component('section-full-chart', sectionFullChart);
Vue.component('section-row-double-icon-background-text', sectionRowDoubleIconBackgroundText);
Vue.component('section-recommend', sectionRecommend);
Vue.component('section-download', sectionDownload);
Vue.component('section-single-video', sectionSingleVideo);
Vue.component('section-links', sectionLinks);
Vue.component('preloader', preloader);
Vue.component('webinar_room', webinarRoom);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
